// Saved Quotes table
export const SavedQuotesTableName = [
  { title: "Cart_Id" },
  { title: "No_Of_Items" },
  { title: "Total_Cost" },
  { title: "" },
];

export const ConfirmedQuotesTableName = [
  { title: "Cart_Id" },
  { title: "Order_Date" },
  { title: "No_Of_Items" },
  { title: "Total_Cost" },
  { title: "To_Ship" },
  { title: "Quote_ID" },
  { title: "Status" },
  { title: "Expiry_Date" },
  { title: "" },
];
export const OrderTableName = [
  { title: "Order_ID" },
  { title: "Customer_ID" },
  { title: "Shipping_Address" },
  { title: "Order_Date" },
  { title: "Total_Amount" },
  { title: "Status" },
  "",
];

export const BestSellarsTitles = [
  "ProductID",
  "ProductName",
  "Category",
  "Sub-Category",
  "Price/Mt",
  "LastPurchase",
  "Quantity",
  "",
];

export const ShipmentDocumentTableName = [
  { title: "Order_ID" },
  { title: "DDT_ID" },
  { title: "Delivery_Date" },
  { title: "Shipping_Address" },
  { title: "Invoice_ID" },
  { title: "Order_Date" },
  "",
];

export const ShipmentDocumentSecondTableName =[
  { title: "Order_ID" },
  { title: "Shipping_Address" },
  { title: "Delivery_Date" },
  { title: "Order_Date" },
  "",
]