import React from "react";

import {
  Typography,
  IconButton,
  // Customizable Area Start
  CircularProgress,
  Button,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { FileCopyOutlined, EditOutlined } from "@material-ui/icons";
import FilterBy from "./FilterBy";
import MainLayout from "../../../components/src/MainLayout.web";
import Table from "../../../components/src/Table.web";
import { debounce, formatPrice } from "../../../components/src/utilities/utils";
import './Quotemanagement.css';
import Dialog from "../../../components/src/Dialog.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import GetAppIcon from '@material-ui/icons/GetApp';
// Customizable Area End

import QuotemanagementController, {
  Props,
  configJSON,
} from "./QuotemanagementController";
import QuoteDetailsView from "./QuoteDetailsView.web";
import { withTranslation } from 'react-i18next';
import { SavedQuotesTableName, ConfirmedQuotesTableName } from "../../../components/src/tableNameConstants";
import { DeleteQuoteDialog } from "./DeleteQuoteDialog.web";
import QuoteDetailsUpdateWeb from "./QuoteDetailsUpdate.web";
import CustomTooltip from "../../../components/src/Tooltip.web";
import ConfirmQuoteDetailsViewWeb from "./ConfirmQuoteDetailsView.web";


export class Quotemanagement extends QuotemanagementController {
  private dialogRef: React.RefObject<Dialog>;
  handleScroll: any = null;
  handleScrollConfirmed : any = null ;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.dialogRef = React.createRef<Dialog>();
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this)
    this.handleScroll = debounce(this.scrollTable, 300)
    this.handleScrollConfirmed = debounce(this.scrollConfirmedQuotesTable , 300)
    // Customizable Area End
  }

  // Customizable Area Start
  openDialog(actionType : string) {
    if (this.dialogRef.current && typeof this.dialogRef.current.openDialog === 'function') {
      this.dialogRef.current.openDialog();
      this.setState({isDialogClosed : false , dialogActionType : actionType})
    } else {
      console.warn("Dialog reference or method openDialog not available");
    }
  }

  closeDialog() {
    if (this.dialogRef.current && !this.state.isDialogClosed) {
      this.dialogRef.current.closeDialog();
      this.setState({isDialogClosed : true})
    } else {
      console.warn("Dialog reference or method closeDialog not available");
    }
  }

  onInputFocus() {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: '' });
    } else {
      console.log("No error message to clear");
    }
  }

  
  addProductToCart(type: string) { 
    const { confirmedQuotesData, productQuantities } = this.state;
    const quoteItems = confirmedQuotesData.quote?.quote_order_items;
    if (!quoteItems || quoteItems.length === 0) {
      console.warn('No items found in the quote_order_items array');
      return;
    }
  
    // Create an array of promises for all products
    const updatePromises = quoteItems.map((quoteData: any) => {
      const key = `id_${quoteData?.product_id}`;
      const qty = productQuantities[key];
  
      if (typeof qty !== 'string') {
        this.setState({ clickedProductId: quoteData?.product_id });
  
        if (type === 'YES') {
          this.cleanCartItems();
        }
  
        return new Promise((resolve) => {
          setTimeout(() => {
            this.updateProductQuantityFromCart(
              quoteData?.product_id,
              quoteData?.quantity,
              quoteData?.quantity === 0 ? 'DELETE' : 'UPDATE'
            )?.then(resolve);
          }, 1000);
        });
      } else {
        console.warn('Invalid quantity type for product ID');
        return Promise.resolve(); // Resolve immediately for invalid quantities
      }
    });
  
    // Wait for all updates to complete before navigating to the cart
    Promise.all(updatePromises).then(() => {
      this.navigateToCart();
    });
  }
  
  

  openPopup(confirmQuoteItem: any) {
    this.setState({ confirmedQuotesData: confirmQuoteItem }, () => {
      const customer_id: string | null = localStorage.getItem('customer_id');
      const data = JSON.parse(localStorage.getItem(`cart_${customer_id}`) || '{"products": {}}');
      if (Object.keys(data.products).length > 0) {
        this.openDialog('Confirmed');
      } else {
        // Immediately call addProductToCart on the first click
        this.addProductToCart('YES');
      }
    });
  }
  openPopupSaved = async(saveQuoteItem: any) => {
    const customer_id: any=  await getStorageData('customer_id')
      const cart_data = JSON.parse(localStorage.getItem(`cart_${customer_id}`) || '{"products": {}}');
    this.setState({ savedQuotesCartData: saveQuoteItem }, () => {
      if (Object.keys(cart_data.products).length > 0) {
        this.openDialog('Saved');
      } else {
        this.addProductToCartForSavedQuote('YES');
      }
    });
  }
  

  renderQuoteDetailsView = (state: any) => {
    const {
      activeQuote,
      savedQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isOrderLoading,
      isOrderSubmitted,
      orderMethod
    } = state;

    return (
      <QuoteDetailsView
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={savedQuotesData}
        loading={loading}
        submitOrder={this.submitOrder}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isOrderLoading}
        isOrderSubmitted={isOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        fetchQuoteDetail={this.fetchQuoteDetail}
        submitOffer={this.submitOffer}
        DeleteQuote={this.deleteQuoteIfProductDeletedBE}
        isOfferLoading={this.state.isOfferLoading}
      />
    );
  };
  renderQuoteDetailsUpdate = (state: any) => {
    const {
      activeQuote,
      savedQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isOrderLoading,
      isOrderSubmitted,
      orderMethod
    } = state;

    return (
      <QuoteDetailsUpdateWeb
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={savedQuotesData}
        loading={loading}
        submitOrder={this.submitOrder}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isOrderLoading}
        isOrderSubmitted={isOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        cart={this.state.cart}
        fetchCartOnEveryUpdate={this.fetchCartOnEveryUpdate}
        deleteQuoteItem={this.deleteQuoteItem}
        quantity={this.state.quantity}
        product_id={this.state.product_id}
        cartData={this.state.cartData}
        submitOffer={this.submitOffer}
        DeleteQuote={this.deleteQuoteIfProductDeletedBE}
        isOfferLoading={this.state.isOfferLoading}
      />
    );
  };
  renderConfirmQuoteDetailsView = (state: any) => {
    const {
      activeQuote,
      confirmQuotesData,
      loading,
      view,
      addresses,
      newAddressLoading,
      newAddressSubmitted,
      isConfirmOrderLoading,
      isOrderSubmitted,
      isOrderLoading,
      orderMethod
    } = state;

    return (
      <ConfirmQuoteDetailsViewWeb
        page={activeQuote.page}
        id={activeQuote.id}
        activeQuote={confirmQuotesData}
        loading={loading}
        submitOrder={this.submitOrder}
        view={view}
        createNewAddress={this.createNewAddress}
        submitOrderDialogClose={this.submitOrderDialogClose}
        navigateToOrderDetails={this.navigateToOrderDetails}
        createdOrderDetails={state.createdOrderDetails}
        addresses={addresses}
        newAddressLoading={newAddressLoading}
        newAddressSubmitted={newAddressSubmitted}
        isOrderLoading={isOrderLoading}
        isOrderSubmitted={isOrderSubmitted}
        quantityUpdate={this.quantityUpdate}
        orderMethod={orderMethod}
        changeOrderMethod={this.changeOrderMethod}
        fetchQuoteDetail={this.fetchQuoteDetail}
        placeOrderFromConfirmQuotes = {this.placeOrderFromConfirmQuotes}
      />
    );
  };
  renderDefaultView = (state: any, t: any, configJSON: any) => {
    const { loading } = state;

    return (
      <>
        {loading ? (
          <div className="loading-section" data-testid="orders-loading">
            <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
          </div>
        ) : (
          <>
            <Typography variant="h1" component="h1" className="page-title">
              {t('SavedQuotes')}
            </Typography>
            {this.renderSavedQuotesTable(state, t, configJSON)}
            <div className="title-section">
              <Typography variant="h1" component="h1" className="page-title">
                {t('ConfirmedQuotes')}
              </Typography>
              <FilterBy filterValue="CONFIRMED" onFilterValueChanged={() => { }} />
            </div>
            {this.renderConfirmedQuotesTable(state, t, configJSON)}
          </>
        )}
      </>
    );
  };
  renderSavedQuotesTable = (state: any, t: any, configJSON: any) => {
    const { savedQuotes } = state;
  
    // Helper function to filter saved quotes with non-empty order items
    const getFilteredData = (savedQuotes: any[]) => {
      return savedQuotes?.filter(
        (item: { attributes: { order_items: { data: string | any[] } } }) =>
          item.attributes.order_items.data.length !== 0
      );
    };
  
    // Helper function to construct the table row
    const buildTableRow = (item: any) => {
      return {
        cart_id: item.id,
        no_of_items: item.attributes.order_items.data.length,
        total_cost: (
          <div className="price">
            <span className="span">€</span>
            <span>{formatPrice(item.attributes.total_sum)}</span>
          </div>
        ),
        actions: renderActions(item, t),
        extraData: {
          ...item,
          hidden: true,
        },
      };
    };
  
    // Helper function to render actions for each row
    const renderActions = (item: any, t: any) => {
      return (
        <div style={{ display: "flex" }}>
          <CustomTooltip title={t('Copy Quotation')} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <IconButton
              color="primary"
              className="iconBtn1"
              onClick={(event) => { 
                event.stopPropagation()
                this.openPopupSaved(item)
              }
              }
            >
              <FileCopyOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t("Edit")} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <IconButton
              color="primary"
              className="iconBtn2"
              test-id="navigateQuote"
              onClick={(event) =>{
                event.stopPropagation()
                this.navigateToQuoteDetails(
                  item.id,
                  "SAVED_QUOTE",
                  this.state.savedQuotesData,
                  localStorage.setItem("UPDATE", "UPDATE"),
                  localStorage.setItem(
                    "DEFAULT_TYPE",
                    item.attributes.delivery_type === "final_delivery"
                      ? "FINAL_DELIVERY_ONLY"
                      : "PARTIAL_DELIVERY"
                    ),
                  //@ts-ignore
                  item.attributes.address.data?.id,
                  item.attributes.alternate_address
                )
              }
 }
            >
              <EditOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t("Deletebtn")} style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <Box component={"div"} className= 'deleteIconBtn' onClick={(event)=>event.stopPropagation()}>
              <DeleteQuoteDialog onFormSubmit={() => this.deleteQuote(item.id)} t={t} />
            </Box>
          </CustomTooltip>
        </div>
      );
    };
  
    const filteredData = getFilteredData(savedQuotes);
  
    //the scroll pagination
    this.handleScroll = debounce(this.scrollTable, 300);
    if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
      return (
        <div className="scrollable-wrapper" >
          <TableContainer component={Paper} className="pantalone-table saved-quotes-table scrollable-wrapper2" style={webStyle.tableContainer} onScroll={this.handleScroll}>
            <MuiTable>
              <TableHead style={webStyle.stickyHeader}>
                <TableRow>
                  {SavedQuotesTableName.map((table: { title: string }) => t(table.title)).map((title, index) => <TableCell key={index}>{t(title)}</TableCell>)}
                </TableRow>
              </TableHead>

              <TableBody className="savedQuoteTableBody">
                {filteredData.map(buildTableRow).map((item,index) => {
                  return <TableRow key={item.cart_id}
                   style={webStyle.pointer}
                   id={`savedQuoteRow_${index}`}
                    onClick={() =>{
                     return this.navigateToQuoteDetails(
                        item.cart_id,
                        "SAVED_QUOTE",
                        this.state.savedQuotesData,
                        setStorageData("UPDATE", "DETAILS"),
                        setStorageData(
                          "DEFAULT_TYPE",
                            this.handleDeliveryType(item.extraData.attributes.delivery_type)
                        ),
                        item.extraData.attributes.address.data?.id,
                        item.extraData.attributes.alternate_address
                      )
                    }
                      }>
                   {Object.values(item).map((details, index) => {
                      if (details?.hidden) {
                        return
                      }
                      return <TableCell key={index}>{details}</TableCell>
                    })}
                  </TableRow>
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </div>
      );
    } else if (this.state.savedQuotesError) {
      return t(configJSON.noQuotesFound);
    } else {
      return (
        <div className="loading-section" data-testid="orders-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  renderConfirmedQuotesTable = (state: any, t: any, configJSON: any) => {
    const { confirmedQuotes } = state;

    // Helper function to calculate days difference
    const getDaysDifference = (expirationDate: Date) => {
      const currentDate = new Date();
      const timeDifference = expirationDate.getTime() - currentDate.getTime();
      return Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
    };

    // Helper function to format date and time
    const formatDateAndTime = (createdAt: string) => {
      const options = {
        day: '2-digit' as const,
        month: '2-digit' as const,
        year: 'numeric' as const,
        hour: '2-digit' as const,
        minute: '2-digit' as const,
        second: '2-digit' as const,
        hour12: true as const
      };
      const formattedDatetime = new Date(createdAt).toLocaleString('en-GB', options);
      const dateTimeParts = formattedDatetime.toUpperCase().replace(/,/g, '').split(' ');
      return { date: dateTimeParts[0], time: dateTimeParts[1] + ' ' + dateTimeParts[2] };
    };

    // Helper function to render actions for each row
    const renderActions = (item: any, t: any) => {
      return (
        <div className="actions" style={webStyle.actions}>
          <CustomTooltip title={t('Copy Quotation')} style={{ marginTop: '-5px', marginBottom: '-5px' }}>
            <IconButton className="openPopupIconBtn" color="primary" onClick={(event) => {
              event.stopPropagation()
              this.openPopup(item)
              }}>
              <FileCopyOutlined />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={t('Download')} style={{ marginTop: '-5px', marginBottom: '-5px' }}>
            <IconButton className="openPopupIconBtn" color="primary" onClick={(event) => {
              event.stopPropagation()
              }}>
              <GetAppIcon color="primary"  style={{ width: 30, height: 30 }}/>
            </IconButton>
          </CustomTooltip>
        </div>
      );
    };
    this.handleScrollConfirmed = debounce(this.scrollConfirmedQuotesTable, 300);
    if (confirmedQuotes && Array.isArray(confirmedQuotes) && confirmedQuotes.length > 0) {
      return (
        <div className="confirmed-scrollable-wrapper">
          <TableContainer component={Paper} className="pantalone-table confirmed-scrollable-wrapper2" style={webStyle.tableContainer} onScroll={this.handleScrollConfirmed}>
            <MuiTable >
              <TableHead style={webStyle.stickyHeader}>
                <TableRow>
                  {ConfirmedQuotesTableName.map((table: { title: string }) => t(table.title)).map((title, index) => <TableCell key={index}>{t(title)}</TableCell>)}
                </TableRow>
              </TableHead>

              <TableBody className="confirmQuoteTableBody">
                {confirmedQuotes.map((item) => {
                  const quotes_ = item?.quote || {};
                  const expirationDate = new Date(quotes_.expiration_date);
                  const daysDifference = getDaysDifference(expirationDate);
                  const { date, time } = formatDateAndTime(item?.quote?.quote_order_items[0]?.created_at);
                  return {
                    cart_id: quotes_.id,
                    order_date: (
                      <>
                        {date}
                        <br />
                        {time}
                      </>
                    ),
                    no_of_items: quotes_.quote_order_items?.length,
                    total_cost: (
                      <div className="price">
                        <span className="span">€</span>
                        <span>{formatPrice(quotes_.total_sum)}</span>
                      </div>
                    ),
                    to_ship: quotes_.to_ship,
                    quote_id: quotes_.quote_code,
                    status: quotes_.quote_status,
                    expiry_date: daysDifference >= 1 ? expirationDate.toLocaleDateString('en-GB') : t('Expired'),
                    actions: renderActions(item, t),
                    extraData: {
                      ...item,
                      hidden: true,
                    }
                  };
                }).map((item,iindex) => {
                  return <TableRow key={item.cart_id} style={webStyle.pointer}
                  id={`confirmQuoteRow_${iindex}`}
                    onClick={() => {
                      this.navigateToConffirmQuoteDetails(
                        item.cart_id,
                        'CONFIRMED_QUOTE',
                        this.state.confirmQuotesData,
                        setStorageData('UPDATE', 'CONFIRM_VIEW'),
                        setStorageData(
                          'DEFAULT_TYPE',
                          this.handleSingleDelivery(item.extraData?.quote?.single_delivery)
                        ),
                        item.extraData?.quote?.address?.id,
                        item.extraData?.quote?.alternate_address,
                        item.extraData?.quote?.total_sum
                      )
                    }
                    }>
                    {Object.values(item).map((details, index) => {
                      if (!details?.hidden) {
                        return <TableCell key={index}>{details}</TableCell>
                      }
                    })}
                  </TableRow>
                }

                )}
              </TableBody>
            </MuiTable>
          </TableContainer> 
      </div>
      );
    } else if (this.state.confirmedQuotesError) {
      return t(configJSON.noQuotesFound);
    } else {
      return (
        <div className="loading-section" data-testid="orders-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      );
    }
  };
  // Customizable Area End







  render() {
    const { t } = this.props
    // Customizable Area Start
    const customer_id: string | null = localStorage.getItem('customer_id');
    const data = JSON.parse(localStorage.getItem(`cart_${customer_id}`) || "[]");
    // Customizable Area End  

    return (
      // Customizable Area Start
      <MainLayout
        id="quote-management"
        navigation={this.props.navigation}
      >
        {(() => {
          switch (true) {
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'UPDATE':
              return (
                <>
                  {this.renderQuoteDetailsUpdate(this.state)}
                </>
              );
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'DETAILS':
              return (
                <>
                  {this.renderQuoteDetailsView(this.state)}
                </>
              );
            case this.state.view !== "LIST" && localStorage.getItem("UPDATE") === 'CONFIRM_VIEW':
              return (
                <>
                  {this.renderConfirmQuoteDetailsView(this.state)}
                </>
              );
            default:
              return this.renderDefaultView(this.state, t, configJSON);
          }
        })()}
        <Dialog
          id="request-new-product"
          title={this.props.isRequestProductSubmitted ? t('Success') : t(configJSON.requestProductDialogRequestProductText)}
          ref={this.dialogRef}
          closable
          onClose={this.closeDialog}
        >

          <Typography variant="body2" className="description">
            You have {data.products ? Object.keys(data.products).length : 0} items in your cart. Do you want to remove these items from the cart?
          </Typography>

          <div className="Btn-Flex">
            <Button
              data-test-id = "YesBtn"
              className="ButtonDialogYes rounded-button"
              variant="contained"
              color="primary"
              onClick={() => {this.state.dialogActionType === 'Confirmed' ? this.addProductToCart('YES') : this.addProductToCartForSavedQuote('YES')}}
              fullWidth
            >
              Yes
            </Button>
            <Button
              className="ButtonDialogNo rounded-button"
              variant="contained"
              color="primary"
              onClick={() => {this.state.dialogActionType === 'Confirmed' ? this.addProductToCart('NO') : this.addProductToCartForSavedQuote('NO')}}
              fullWidth
            >
              No
            </Button>
          </div>

        </Dialog>
      </MainLayout>
      // Customizable Area End


    );

  }
}
// Customizable Area Start
export default withTranslation()(Quotemanagement)
const webStyle = {
  stickyHeader: {
    position: 'sticky' as 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  tableContainer: {
    maxHeight: '65vh',
    overflowY: 'auto' as 'auto',
  },
  pointer : {cursor:"pointer"},
  actions : {display:'flex',flexDirection:"row" as "row",justifyContent:'space-around'},
}
// Customizable Area End
