import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IConfirmedQuote, ISavedQuote, IActiveQuoteData } from "./types";
import { addHashToUrl, formatPrice } from "../../../components/src/utilities/utils";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { IAddress, ICart, IProduct } from "../../../components/src/types/types";
import { ICreatedOrderDetails } from "./PlaceOrderDialog.web";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t: any,
  // Customizable Area Start
  products: IProduct[],
  isRequestProductSubmitted: boolean,
  isRequestProductLoading: boolean,
  onFormSubmit: (email: string) => void,
  onDialogClose: () => void,
  onCartItemUpdate: (productId: IProduct['product_id'], qty: number) => void,
  fetchCatalogue: (pageNumber: number) => void,
  productSearchValue?: string,
  isNewProductsLoading: boolean,
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  savedQuotes: ISavedQuote[];
  confirmedQuotes: IConfirmedQuote[];
  activeQuote: { id: number, page: 'SAVED_QUOTE' | 'CONFIRMED_QUOTE', data?: IActiveQuoteData };
  token: string;
  loading: boolean;
  loadingData: Record<string, boolean>;
  savedQuotesData: ISavedQuote[];
  isOrderLoading: boolean;
  page: 'SAVED_QUOTE' | 'CONFIRMED_QUOTE';
  orderMethod: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
  view: 'LIST' | 'DETAILS' | 'UPDATE' | 'CONFIRM_DETAILS' | 'CHECKOUT',
  newAddressLoading: boolean,
  addresses: (IAddress & { id: number })[],
  isOrderSubmitted: boolean,
  newAddressSubmitted: boolean,
  createdOrderDetails?: ICreatedOrderDetails,
  cart: ICart;
  isCartLoading: boolean;
  quoteData: [];
  activeCartId: number;
  quantity: number;
  product_id: any;
  cartData: any;
  isOfferLoading: boolean;
  isOfferSubmitted: boolean;
  confirmQuotesData: any;
  submitOffersStaticData: any;
  savedQuotesError: string;
  confirmedQuotesError: string;
  isConfirmOrderLoading: boolean;
  isConfirmOrderSubmitted: boolean;
  productName: string;
  productQuantities: Record<string, number | string>
  clickedProductId: IProduct['id'] | string
  clickedProductSaved : any
  errorMessage?: string,
  products: IProduct[],
  confirmedQuotesData: any,
  savedQuotesCartData : any ,
  loaderTable : boolean , 
  isLoadingQuotes: boolean,
  hasMore : boolean , 
  isLoadingConfirmedQuotes : boolean , 
  hasMoreConfirmed : boolean,
  isDialogClosed : boolean;
  dialogActionType : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuotemanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchSavedQuotesApiCall: string = '';
  fetchSavedQuotesDetailsApiCall: any = '';
  createQuoteItemApiCallId: string = '';
  createAddressApiCallId: string = '';
  validationApiCallId: string = '';
  fetchAddressesApiCall: string = '';
  deleteQuoteApiCall: string = '';
  fetchShoppingCartCallId: string = '';
  fetchSaveDetails: string = '';
  fetchdeleteQuoteItem: string = '';
  fetchSaveDetailsOnEveryUpdate: string = '';
  createConfirmQuoteApiCallId: string = '';
  fetchConfirmedQuotesApiCall: string = '';
  fetchConfirmedQuotesDetailsApiCall: string = '';
  notificationCallId: string = ''
  placeOrderFromConfirmQuotesApiCallId: string = '';
  submitItemApiCallId : string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.submitOrderDialogClose = this.submitOrderDialogClose.bind(this);
    this.createNewAddress = this.createNewAddress.bind(this);
    this.fetchShippingAddresses = this.fetchShippingAddresses.bind(this);
    this.hashListener = this.hashListener.bind(this);
    this.navigateToOrderDetails = this.navigateToOrderDetails.bind(this);
    this.quantityUpdate = this.quantityUpdate.bind(this);
    this.deleteQuote = this.deleteQuote.bind(this);
    this.changeOrderMethod = this.changeOrderMethod.bind(this);
    this.deleteQuoteItem = this.deleteQuoteItem.bind(this);
    this.fetchCartOnEveryUpdate = this.fetchCartOnEveryUpdate.bind(this)
    this.submitOffer = this.submitOffer.bind(this);
    this.deleteQuoteIfProductDeletedBE = this.deleteQuoteIfProductDeletedBE.bind(this)
    this.placeOrderFromConfirmQuotes = this.placeOrderFromConfirmQuotes.bind(this)
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];


    this.state = {
      txtInputValue: "",
      txtSavedValue: "hello@aol.com",
      enableField: false,
      // Customizable Area Start
      savedQuotes: [],
      confirmedQuotes: [],
      activeQuote: { id: "", page: "", data: [] } as unknown as S['activeQuote'],
      token: "",
      loading: true,
      loadingData: {},
      savedQuotesData: [],
      isOrderLoading: false,
      page: "SAVED_QUOTE",
      //@ts-ignore
      orderMethod: localStorage.getItem('DEFAULT_TYPE'),
      view: 'LIST',
      newAddressLoading: false,
      addresses: [] as S['addresses'],
      isOrderSubmitted: false,
      newAddressSubmitted: false,
      cart: { products: {}, totalPrice: 0, prices: {}, productDetails: {} },
      isCartLoading: false,
      quoteData: [],
      activeCartId: -1,
      quantity: 0,
      product_id: "",
      cartData: { products: {} },
      isOfferLoading: false,
      isOfferSubmitted: false,
      confirmQuotesData: [],
      submitOffersStaticData: [],
      savedQuotesError: "",
      confirmedQuotesError: "",
      isConfirmOrderLoading: false,
      isConfirmOrderSubmitted: false,
      productName: '',
      productQuantities: {} as S['productQuantities'],
      clickedProductId: -1 , 
      isLoadingQuotes: false , 
      hasMore : true , 
      clickedProductSaved : -1,
      isLoadingConfirmedQuotes : false  , 
      hasMoreConfirmed : true,
      isDialogClosed : true ,
      savedQuotesCartData : [] , 
      dialogActionType : ''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      // Customizable Area End
    ]);

    // Customizable Area Start
    this.initCart = this.initCart.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (MergeEngineUtilities.isLoggedIn()) {
      this.initCart();
      this.fetchShippingAddresses();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
      this.notificationCallId = await MergeEngineUtilities.getNotification()
    }
    this.fetchSavedQuotes();
    this.fetchConfirmedQuotes();


    this.hashListener();
    window.addEventListener('hashchange', this.hashListener, true);
   removeStorageData('total_sum');
   removeStorageData(`confirmData`);

    // Customizable Area End
  }

  async componentWillUnmount() {
    window.removeEventListener('hashchange', this.hashListener, true);
  }

  hashListener = () => {
    const isHashExist = !!window.location.hash;

    if (!isHashExist) {
      this.setState({
        view: 'LIST',
      });
      return;
    }

    this.setState({ view: 'DETAILS' });
    const hashValue = window.location.hash.substring(1);

    if (localStorage.getItem("UPDATE") !== "CONFIRM_VIEW") {
      this.fetchQuoteDetail(hashValue);
    } else {
      this.fetchConfirmQuoteDetail(hashValue);
    }
  };


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.handleOrderDetailsResponse(responseJson, apiRequestCallId);
      this.handleValidationResponse(responseJson, apiRequestCallId);
      this.handleSubmitResponse(responseJson, apiRequestCallId);
      this.handleAddressesResponse(responseJson, apiRequestCallId);
      this.handleCreateAddressResponse(responseJson, apiRequestCallId);
      this.handleSavequotsResponse(responseJson, apiRequestCallId);
      this.handleDeleteQuoteResponse(responseJson, apiRequestCallId);
      this.handleFetchShoppingCartResponse(responseJson, apiRequestCallId);
      this.handleDeleteQuoteItemResponse(responseJson, apiRequestCallId);
      this.handleFetchSaveQuoteResponse(responseJson, apiRequestCallId);
      this.handleConfirmedQuotesResponse(responseJson, apiRequestCallId);
      this.handleConfirmQuoteDetailsResponse(responseJson, apiRequestCallId);
      this.handleSubmitOfferResponse(responseJson, apiRequestCallId);
      this.handlePlaceOrderSubmitResponse(responseJson, apiRequestCallId)

      if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
        const data = message.getData(getName(MessageEnum.NavigationData));
        if (data && data.navigateFrom === 'sidebar') {
          this.setState({
            view: 'LIST'
          });
        }
        if (data && data.order_id) {
          addHashToUrl(data.order_id);
          this.setState({ view: 'DETAILS' });
        }
        this.setState({
          page: "SAVED_QUOTE",
          //@ts-ignore
          hasStockIssue: false,
          orderMethod: 'PARTIAL_DELIVERY',
        });
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  handleSavequotsResponse(responseJson: any, apiRequestCallId: string) {
    if (this.fetchSavedQuotesApiCall === apiRequestCallId) {
      if (!responseJson.message) {
        const newQuotes = responseJson.data.map((quote: any) => ({
          attributes : quote.attributes,
          id: parseInt(quote.id),
          type : quote.type
        }));
        this.setState((prevState) => {
          const quoteSet = new Set((prevState.savedQuotes || []).map((quote: any) => quote.id));
          const uniqueQuotes = newQuotes.filter((quote: any) => !quoteSet.has(quote.id));
          return {
            savedQuotes: [...(prevState.savedQuotes || []), ...uniqueQuotes],
          };
        }, () => {
          this.setState({ isLoadingQuotes: false });
        });
  
      } else {
        this.setState({ savedQuotesError: responseJson , hasMore : false });
      }
    }
  }
  handleConfirmedQuotesResponse(responseJson: any, apiRequestCallId: string) {
    if (this.fetchConfirmedQuotesApiCall === apiRequestCallId) {
      if (!responseJson.error) {
        const newConfirmedQuotes = responseJson.map((item: any) => ({
          quote: item.quote,
          address: item.address,
        }));
        this.setState((prevState) => {
          const confirmedQuoteSet = new Set(
            prevState.confirmedQuotes
              .map((item : any) => item?.quote?.id)
          );
        
          const uniqueConfirmedQuotes = newConfirmedQuotes.filter(
            (quote: { quote: { id: number } | null }) =>
              quote.quote && !confirmedQuoteSet.has(quote.quote.id)
          );
          return {
            confirmedQuotes: [...prevState.confirmedQuotes, ...uniqueConfirmedQuotes],
            hasMoreConfirmed: uniqueConfirmedQuotes.length > 0,
          };
        }, () => {
          this.setState({ isLoadingConfirmedQuotes: false });
        });
      } else {
        this.setState({ confirmedQuotesError: responseJson, hasMoreConfirmed: false });
      }
    }
  }
 
  scrollTable = (event: any) => {
    const el = document.querySelector('.scrollable-wrapper2'); 
    if (!el || this.state.isLoadingQuotes || !this.state.hasMore) {
      return;
    }

    if (el.scrollHeight - el.scrollTop <= el.clientHeight + 75 && this.state.savedQuotes.length >= 10) {
     this.fetchSavedQuotes(Math.floor(this.state.savedQuotes.length / 10) + 1);
    }
  };


  handleSubmitOfferResponse(responseJson: any, apiRequestCallId: string) {
    if (this.createConfirmQuoteApiCallId === apiRequestCallId) {
      if (responseJson) {
        window.location.href = "/Quotemanagement"
      }
    }
  }

  scrollConfirmedQuotesTable = (event: any) => {
    const el = document.querySelector('.confirmed-scrollable-wrapper2'); 
    if (!el || this.state.isLoadingConfirmedQuotes || !this.state.hasMoreConfirmed) {
      return;
    }
    if (el.scrollHeight - el.scrollTop <= el.clientHeight + 75 && this.state.confirmedQuotes.length >= 10) {
      this.fetchConfirmedQuotes(Math.floor(this.state.confirmedQuotes.length / 10) + 1);
    }
  };

  handleDeleteQuoteResponse(responseJson: any, apiRequestCallId: string) {
    if (this.deleteQuoteApiCall === apiRequestCallId) {
      this.setState({ loading: false })
      this.fetchSavedQuotes()
      this.setState({ savedQuotes: responseJson.data })
    }
  }
  handleDeleteQuoteItemResponse(responseJson: any, apiRequestCallId: string) {
    if (this.fetchdeleteQuoteItem === apiRequestCallId) {
      this.setState({ loading: false })
      if (responseJson) {
        const cart_id = window.location.hash.substring(1)
        if (localStorage.getItem("UPDATE") !== "CONFIRM_VIEW") {
          this.fetchQuoteDetail(cart_id)
        }
        this.setState({ savedQuotesData: responseJson.data })
      }
    }
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.validationApiCallId) {
      if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
        this.initCart();
        this.fetchShippingAddresses();
      } else {
        MergeEngineUtilities.logout(this.props);
      }
    }
    if (apiRequestCallId === this.notificationCallId) {
      if (responseJson.data?.length) {
        localStorage.setItem("Notification_count", responseJson?.data?.length)
      } else {
        localStorage.setItem("Notification_count", '0')
      }
    }
  }
  submitOffersTrasformObject = (submitdataObject: any) => {
    const productId = Object.keys(submitdataObject)[0];
    const product_Id = submitdataObject[productId]?.product_id;
    const productDescription = submitdataObject[productId]?.description;
    const productName = submitdataObject[productId]?.name;
    const productSubCategory = submitdataObject[productId]?.sub_category?.name;
    const productBaseUnit = submitdataObject[productId]?.base_unit;
    const productCategory = submitdataObject[productId]?.category?.name;

    return {
      "product_name": productName,
      "product_base_unit": productBaseUnit,
      "product_description": productDescription,
      "product_category": productCategory,
      "product_id": product_Id,
      "product_sub_category": productSubCategory,
      "to_ship": true,
      "order_date": new Date().toLocaleDateString('en-GB'),
      "fulfillment_type": "standard"
    };
  };
  submitConfirmOrderformObject = (submitdataObject: any) => {
    const productId = Object.keys(submitdataObject)[0];
    const product_Id = submitdataObject[productId].product_id;
    const productDescription = submitdataObject[productId].product_description;
    const productName = submitdataObject[productId].product_name;
    const productSubCategory = submitdataObject[productId].product_sub_category;
    const productBaseUnit = submitdataObject[productId].product_base_unit;
    const productCategory = submitdataObject[productId].product_category;
    const productPrice = submitdataObject[productId].product_price;
    const productQuantity = submitdataObject[productId].quantity;

    return {
      "product_id": product_Id,
      "price": productPrice,
      "quantity": productQuantity,
      "total_cost": localStorage.getItem("total_sum"),
      "product_name": productName,
      "product_description": productDescription,
      "product_base_unit": productBaseUnit,
      "product_category": productCategory,
      "product_sub_category": productSubCategory,
    };
  };

  handleFetchShoppingCartResponse(responseJson: any, apiRequestCallId: string) {
    if (this.fetchSaveDetails === apiRequestCallId) {
      this.setState({ loading: false })
      if (responseJson) {
        this.setState({ savedQuotesData: responseJson.data })
      }
    }
  }
  handleFetchSaveQuoteResponse(responseJson: any, apiRequestCallId: string) {
    if (this.fetchSaveDetailsOnEveryUpdate === apiRequestCallId) {
      this.setState({ loading: false })
      if (responseJson) {
        const cart_id = window.location.hash.substring(1)
        if (localStorage.getItem("UPDATE") !== "CONFIRM_VIEW") {
          this.fetchQuoteDetail(cart_id)
        }
        this.setState({ savedQuotesData: responseJson.data })
      }
    }
  }
  async handleOrderDetailsResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.fetchSavedQuotesDetailsApiCall.current) { return; }

    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      this.setState({ savedQuotesData: responseJson.data })
      if (localStorage.getItem("UPDATE") !== "CONFIRM_VIEW") {
        if (this.state.activeQuote.id && responseJson.data.attributes?.order_items.data.length === 0) {
          this.deleteQuote(this.state.activeQuote.id)
          window.location.href = "/Quotemanagement"
        }
      }
      const cart = await MergeEngineUtilities.addProductDetailsToCartSubmitData(responseJson.data.attributes);

      this.setState({ cart: cart })
      const newArray = [];
      for (const id in cart.productDetails) {
        if (cart.productDetails.hasOwnProperty(id)) {
          const item = {
            [id]: cart.productDetails[id]
          };
          newArray.push(item);
        }
        const submitOffersArray = newArray.map(this.submitOffersTrasformObject);
        this.setState({ submitOffersStaticData: submitOffersArray })
      }
    }
  }
  async handleConfirmQuoteDetailsResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchConfirmedQuotesDetailsApiCall) { return; }

    this.setState({ loading: false });
    if (responseJson && responseJson.quote) {
      this.setState({ confirmQuotesData: responseJson })
      const cart = await MergeEngineUtilities.addProductDetailsToCartData(responseJson.quote);
      this.setState({ cart: cart })
      const newArray = [];
      for (const id in cart.productDetails) {
        if (cart.productDetails.hasOwnProperty(id)) {
          const item = {
            [id]: cart.productDetails[id]
          };
          newArray.push(item);
        }
        const submitOffersArray = newArray.map(this.submitConfirmOrderformObject);
        this.setState({ submitOffersStaticData: submitOffersArray })
      }
    }
    // } 
  }

  handleCreateAddressResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.createAddressApiCallId) { return; }

    if (responseJson && responseJson.address) {
      this.setState({ newAddressSubmitted: true, newAddressLoading: false })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleAddressesResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchAddressesApiCall) { return; }

    const state: Partial<S> = {}
    if (responseJson && Array.isArray(responseJson.addresses)) {
      state.addresses = responseJson.addresses;
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.setState((prevState) => ({ ...prevState, ...state }))
  }

  async handleSubmitResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.submitItemApiCallId) { return; }
    const state: Partial<S> = { isOrderLoading: false }
    if (responseJson && responseJson.data) {
      state.isOrderSubmitted = true;

      state.createdOrderDetails = {
        id: responseJson.data.id,
        created_at: new Date(responseJson.data.attributes.created_at).toLocaleDateString('en-GB'),
        customer_id: responseJson.data.attributes.customer.data.attributes.customer_code,
        total_amount: formatPrice(responseJson.data.attributes.total_sum)!,
        status: responseJson.data.attributes.status
      };

      const cart = await MergeEngineUtilities.cleanCart();
      state.cart = cart;
      this.notificationCallId = await MergeEngineUtilities.getNotification()
      if (localStorage.getItem("UPDATE") !== "CONFIRM_VIEW") {
        setTimeout(() => {
          const cart_id = localStorage.getItem("Cart_Id")
          this.deleteQuote(cart_id)
        }, 2000);
      }

    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
  }
  async handlePlaceOrderSubmitResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.placeOrderFromConfirmQuotesApiCallId) { return; }
    const state: Partial<S> = { isConfirmOrderLoading: false,isOrderLoading : false }

    if (responseJson && responseJson.data) {
      state.isOrderSubmitted = true;
      state.isConfirmOrderSubmitted = true;

      state.createdOrderDetails = {
        id: responseJson.data.id,
        customer_id: responseJson.data.attributes.customer.data.attributes.customer_code,
        status: responseJson.data.attributes.status,
        created_at: new Date(responseJson.data.attributes.created_at).toLocaleDateString('en-GB'),
        total_amount: formatPrice(responseJson.data.attributes.total_sum)!
      };

      const cart = await MergeEngineUtilities.cleanCart();
      state.cart = cart;

    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
  }

  async handleConfirmQuoteResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.createQuoteItemApiCallId) { return; }
    const state: Partial<S> = { isOfferLoading: false }

    if (responseJson && responseJson.data) {
      state.isOfferSubmitted = true;

      MergeEngineUtilities.navigateToScreen('OrderManagement', this.props)
    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
  }

  navigateToOrderDetails() {
    if (this.state.createdOrderDetails && this.state.createdOrderDetails.id) {
      MergeEngineUtilities.navigateToScreen('OrderManagement', this.props, {
        order_id: this.state.createdOrderDetails.id
      });
    }
  }

  changeOrderMethod(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ orderMethod: event.target.value as S['orderMethod'] })
  }

  fetchSavedQuotes(pageNumber: number = 1) {
    this.setState({ isLoadingQuotes: true });
    setTimeout(() => {
      if (pageNumber % 1 !== 0) {
        return;
      }
  
      this.setState({ loaderTable: pageNumber !== 1 }, () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
        this.fetchSavedQuotesApiCall = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.fetchSavedQuotesMethod}?page=${pageNumber}`
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({ token: localStorage.getItem("authToken") })
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      });
    }, 0);
  }

  fetchConfirmedQuotes(pageNumber: number = 1) {
    const customer_code_param = localStorage.getItem('customer_code');
    const customer_id_param = localStorage.getItem('customer_id');
  
    this.setState({ isLoadingConfirmedQuotes: true });
  
    setTimeout(() => {
      if (pageNumber % 1 !== 0) {
        return;
      }
  
      this.setState({ loaderTable: pageNumber !== 1 }, () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
        this.fetchConfirmedQuotesApiCall = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.submitOffersApiEndpoint}?customer_code=${customer_code_param}&customer_id=${customer_id_param}&page=${pageNumber}`
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({ token: localStorage.getItem("authToken") })
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      });
    }, 0);
  }

  deleteQuote(quoteId: any) {
    const header = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuoteApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchSavedQuotesMethod}/${quoteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteQuoteIfProductDeletedBE(quoteId: any) {
    const headerData = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuoteApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchSavedQuotesMethod}/${quoteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      window.location.href = "/Quotemanagement"
    }, 1000);
  }

  deleteQuoteItem(quoteId: number) {
    const header = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchdeleteQuoteItem = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quoteDetailsApiMethod}/${quoteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      const cart_id = window.location.hash.substring(1)
      const cartData = localStorage.getItem('cartData');
      const dataArray: any[] = [];

      if (cartData) {
        const cartItemsData: ICart = JSON.parse(cartData);
        Object.values(cartItemsData.products).forEach((item) => {
          if (typeof item === 'object' && item !== null && 'product_id' in item && 'cart_quantity' in item) {
            dataArray.push({ product_id: item.product_id, quantity: item.cart_quantity });
          }
        });
      }

      if (dataArray.length === 0) {
        return this.setState({ isCartLoading: false });
      }

      const requestMessage1 = MergeEngineUtilities.createRequestMessage({
        endpoint: `${configJSON.quoteDetailsApiMethod}/${cart_id}`,
        method: configJSON.patchApiMethod,
        data: {
          order_items: dataArray
        },
        header: {},
        useToken: true,
      })

      this.fetchSaveDetails = requestMessage1.messageId;

      runEngine.sendMessage(requestMessage1.id, requestMessage1);
    }, 1000);
  }

  async fetchCartOnEveryUpdate() {
    this.setState({ loading: true })
    const cart_id = window.location.hash.substring(1)
    const cartOnUpdate = await localStorage.getItem('cartData');
    const dataOnUpdateQuote: any[] = [];

    if (cartOnUpdate) {
      const cartItemsOnUpdate: ICart = JSON.parse(cartOnUpdate);
      Object.values(cartItemsOnUpdate.products).forEach((item) => {
        if (typeof item === 'object' && item !== null && 'product_id' in item && 'cart_quantity' in item) {
          dataOnUpdateQuote.push({ product_id: item.product_id, quantity: item.cart_quantity });
        }
      });
    }

    if (dataOnUpdateQuote.length === 0) {
      return this.setState({ isCartLoading: false });
    }

    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.quoteDetailsApiMethod}/${cart_id}`,
      method: configJSON.patchApiMethod,
      data: {
        order_items: dataOnUpdateQuote
      },
      header: {},
      useToken: true,
    })

    this.fetchSaveDetailsOnEveryUpdate = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      this.fetchSavedQuotes()
    }, 2000);
  }

  async quantityUpdate(productId: any, qty: any) {
    this.setState({ product_id: productId, quantity: qty });
    const newCartItem = {
      product_id: productId,
      cart_quantity: qty,
    };

    try {
      //@ts-ignore
      const existingCartData = JSON.parse(localStorage.getItem('cartData')) || { products: {} };

      const newCartData = {
        products: {
          ...existingCartData.products,
          [`id_${productId}`]: newCartItem,
        },
      };

     await setStorageData('cartData', JSON.stringify(newCartData));
      this.setState({ cartData: newCartData })
    } catch (error) {
      console.error('Error updating cart data:', error);
    }
  }
  

  fetchQuoteDetail = (quote_id: any) => {
    this.setState({ loading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSavedQuotesDetailsApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.quoteDetailsApiMethod}/${quote_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ ...configJSON.apiHeader, token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchConfirmQuoteDetail = (quote_id: any) => {
    this.setState({ loading: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchConfirmedQuotesDetailsApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.submitOffersApiEndpoint}/${quote_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  findSubmitOrderStaticData = (productId: any) => {
        return this.state.submitOffersStaticData.find((staticData: { product_id: string; }) => staticData.product_id === productId);
  }

  async submitOrder(addressId?: string, alternateAddress?: string) {
    if (this.state.view === 'DETAILS') {
      this.setState({
        view: 'CHECKOUT',
      });
    }
    else {
    this.setState({ isOrderLoading: true });

    const cartSubmitData = localStorage.getItem('cartData');

    const submitOrderData: any[] = [];

    if (cartSubmitData) {
      const cartSubmitItems: ICart = JSON.parse(cartSubmitData);
      Object.values(cartSubmitItems.products).forEach((item) => {
        if (typeof item === 'object' && item !== null && 'product_id' in item && 'cart_quantity' in item) {
          const submitOrderStaticData = this.findSubmitOrderStaticData(item.product_id);
          submitOrderData.push({ product_id: item.product_id, quantity: item.cart_quantity, ...submitOrderStaticData });
        }
      });
    }

    if (submitOrderData?.length === 0) {
      return this.setState({ isCartLoading: false });
    }
    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.createOrderApiMethod}`,
      method: configJSON.postApiMethod,
      header: {},
      data: {
        order_items: submitOrderData,
        quote_status : 0,
        status: 1,
        address_id: addressId !== 'alternate-address' && addressId,
        alternate_address: alternateAddress,
        delivery_type: this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1,
        isConfirmedQuote : Number(await getStorageData("UPDATE") === 'CONFIRM_VIEW'),
      },
      useToken: true
    });

    this.submitItemApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
  }


  async placeOrderFromConfirmQuotes(addressId?: string, alternateAddress?: string) {

    this.setState({ isOrderLoading:true,isConfirmOrderLoading: true });

    const cartPlaceOrderData = await getStorageData('confirmData');

    const placeOrderData: any[] = [];
    if (cartPlaceOrderData) {
      const cartSubmitItems: ICart = JSON.parse(cartPlaceOrderData);

      Object.values(cartSubmitItems).forEach((item) => {
        if (typeof item === 'object' && item !== null && 'product_id' in item) {
          placeOrderData.push({
             product_id: item.product_id,
             quantity: item.quantity,
             price : item.product_price,
             static_product_nam : item.product_name,
             static_product_description: item.product_description,
             static_base_unit: item.product_base_unit,
             static_category: item.product_category,
             static_sub_category: item.product_sub_category,
             static_product_id: item.product_id,
              });
        }
      });
    }
    if (placeOrderData?.length === 0) {
      return this.setState({ isCartLoading: false });
    }
    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.createOrderApiMethod}`,
      method: configJSON.postApiMethod,
      header: {},
      data: {
        order_items: placeOrderData,
        quote_status : 0,
        status: 1,
        address_id: addressId !== 'alternate-address' && addressId,
        alternate_address: alternateAddress,
        delivery_type: this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1,
        isConfirmedQuote : Number(await getStorageData("UPDATE") === 'CONFIRM_VIEW'),
        customer_id: localStorage.getItem('customer_id'),
        total_sum: localStorage.getItem('total_sum')
      },
      useToken: true
    });

    this.placeOrderFromConfirmQuotesApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
}


  findSubmitOffersStaticDataC = (productId: any) => {
    return this.state.submitOffersStaticData.find((staticData: { product_id: string; }) => staticData.product_id === productId);
  }

async submitOffer(addressId?: string, alternateAddress?: string, deliveryType?: string) {
    this.setState({ isOfferLoading: true });
    const submitOfferCart = await getStorageData('cartData');

    const submitOfferData: any[] = [];
    if (submitOfferCart && Object.keys(JSON.parse(submitOfferCart).products).length !==0) {
      const cartPlaceItems: ICart = JSON.parse(submitOfferCart);
      Object.values(cartPlaceItems.products).forEach((item) => {
        if (typeof item === 'object' && item !== null && 'product_id' in item && 'cart_quantity' in item) {
          const submitStaticData = this.findSubmitOffersStaticDataC(item.product_id);
          submitOfferData.push({ product_id: item.product_id, quantity: item.cart_quantity, ...submitStaticData });
        }
      });
    } else {

      return this.setState({ isCartLoading: false ,isOfferLoading : false });
    }

    this.deleteQuote(this.state.activeQuote.id)
    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.submitOffersApiEndpoint}`,
      method: configJSON.postApiMethod,
      header: {},
      data: {
        quote_order_items: submitOfferData,
        quote_status: 1,
        address_id: addressId !== 'alternate-address' && addressId,
        alternate_address: alternateAddress,
        single_delivery: this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1,
        customer_code: localStorage.getItem('customer_code'),
        to_ship: 1,
        customer_id: localStorage.getItem('customer_id'),
      },
      useToken: true
    });

    this.createConfirmQuoteApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  submitOrderDialogClose() {
    this.setState({
      isOrderSubmitted: false,
      isOrderLoading: false,
      newAddressLoading: false,
      newAddressSubmitted: false,
      isOfferLoading: false,
      isOfferSubmitted: false,
      isConfirmOrderLoading: false,
      isConfirmOrderSubmitted: false,
    });
  }

  fetchShippingAddresses() {
    const profile_id = MergeEngineUtilities._profile_id;

    const addressRequestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchAddressesMethod}?customer_code=${profile_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchAddressesApiCall = addressRequestMessage.messageId;

    runEngine.sendMessage(addressRequestMessage.id, addressRequestMessage);
  }

  createNewAddress(address: string) {
    this.setState({
      newAddressLoading: true
    }, () => {
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: configJSON.requestProductApiEndPoint,
        method: configJSON.postApiMethod,
        data: {
          request_address: {
            full_address: address,
            customer_code: MergeEngineUtilities._profile_id
          }
        },
        header: {},
        useToken: true,
      })

      this.createAddressApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start
  navigateToQuoteDetails(id: S['activeQuote']['id'], page: S['activeQuote']['page'], data: any, view?: any, default_delivery_type?: any, address_id?: any, default_address?: any) {
    addHashToUrl(`${id}`);
    localStorage.setItem('address_id', address_id)
    localStorage.setItem('alternate_address', default_address)
    this.setState({ activeQuote: { id: id, page: page, data: data } });
    localStorage.setItem("Cart_Id", id.toString())
    localStorage.setItem("open", "true")
    //@ts-ignore
    this.setState({ orderMethod: localStorage.getItem('DEFAULT_TYPE') })
  }
  navigateToConffirmQuoteDetails(id: S['activeQuote']['id'], page: S['activeQuote']['page'], data: any, view?: any, default_delivery_type?: any, address_id?: any, default_address?: any, total_sum?: any) {
    addHashToUrl(`${id}`);
    localStorage.setItem('address_id', address_id)
    localStorage.setItem('alternate_address', default_address)
    localStorage.setItem('total_sum', total_sum)
    this.setState({ activeQuote: { id: id, page: page, data: data } });
    this.fetchConfirmQuoteDetail(id);
    //@ts-ignore
    this.setState({ orderMethod: localStorage.getItem('DEFAULT_TYPE') })
  }

  async initCart() {
    const cart = await MergeEngineUtilities.initCart();
    this.setState({ cart: cart })
    this.fetchShoppingCart(cart);
  }

  async cleanCartItems() {
    const cart = await MergeEngineUtilities.cleanCart();
    this.setState({ cart: cart })
  }

  async updateProductQuantityFromCart(
    productId: IProduct['product_id'], 
    qty: number, 
    operation: 'DELETE' | 'UPDATE',
  ): Promise<void> {
    const { cart } = this.state;
  
    let updatedQuantity = qty;
  
    // Check if product exists in cart and get maximum of existing and new quantities
    if (cart.products[`id_${productId}`]) {
      const existingQuantity = cart.products[`id_${productId}`].cart_quantity;
      updatedQuantity = Math.max(existingQuantity, qty);
    }
  
    // Proceed with update or delete based on operation
    const updatedCart = await MergeEngineUtilities.updateCart(productId, updatedQuantity, operation);
  
    // Update the cart state with the latest cart
    this.setState({ cart: updatedCart });
  }
  
    

  navigateToCart() {
    MergeEngineUtilities.navigateToScreen('ShoppingCartOrders', this.props, { navigateFrom: 'cart' })
  }

  fetchShoppingCart(cart: ICart) {
    const data: any[] = [];

    Object.values(cart.products).forEach((item) => {
      data.push({ product_id: item.product_id, quantity: item.cart_quantity });
    });

    if (data.length === 0) {
      return this.setState({ isCartLoading: false });
    }

    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: configJSON.fetchShoppingCartApiEndpoint,
      method: configJSON.postApiMethod,
      data: {
        order_items: data
      },
      header: {},
      useToken: true,
    })

    this.fetchShoppingCartCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeliveryType = (type? : string) => {
    return type === "final_delivery"
    ? "FINAL_DELIVERY_ONLY"
    : "PARTIAL_DELIVERY"
  }
  handleSingleDelivery = (count : number) => {
   return count === 1 ? 'FINAL_DELIVERY_ONLY' : 'PARTIAL_DELIVERY'
  }
  addProductToCartForSavedQuote(type : string) {
    const { savedQuotesCartData } = this.state;
  const quoteItems = savedQuotesCartData.attributes.order_items.data;
  if (!quoteItems || quoteItems.length === 0) {
   //items not found
    return;
  }

  const processItem = (index: number) => {
    if (index >= quoteItems.length) {
      this.navigateToCart();
      return;
    }

    const quoteData = quoteItems[index];
    const productId = quoteData.attributes.catalogue.data.attributes.product_id;
    const quantity = quoteData.attributes.quantity;

    this.setState({ clickedProductSaved: productId }, () => {
      if (type === 'YES' && index === 0) {
        this.cleanCartItems();
      }

      setTimeout(() => {
        this.updateProductQuantityFromCart(productId, quantity, quantity === 0 ? 'DELETE' : 'UPDATE');
        processItem(index + 1);
      }, 1000); 
    });
  };

  processItem(0);
}
  // Customizable Area End
}
