// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Link,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import {
  classNames,
  formatPrice,
} from "../../../components/src/utilities/utils";
import { withTranslation } from "react-i18next";
import Table from "../../../components/src/Table.web";
import { PlaceOrderDialog } from "./PlaceOrderDialog.web";
import  CustomizedDialogs  from "../../../components/src/ExpireDetailsDailog";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import Input from "../../../components/src/Input.web";
import { IActiveOrder } from "./types";
import { configJSON } from "./QuotemanagementController";

// Customizable Area End
const ConfirmQuoteDetailsView: React.FC<IProps> = ({
  loading,
  page,
  activeQuote,
  t,
  addresses,
  newAddressLoading,
  newAddressSubmitted,
  isOrderSubmitted,
  isOrderLoading,
  orderMethod,
  submitOrder,
  submitOrderDialogClose,
  createdOrderDetails,
  navigateToOrderDetails,
  createNewAddress,
  changeOrderMethod,
  quantityUpdate,
  placeOrderFromConfirmQuotes,

}) => {
  const [activeOrder, setActiveOrder] = useState<IActiveOrder[]>([]);
  const [isUpdate,setisUpdate] = useState(false);
  const [changeValue, setChangeValue] = useState(0);
  const [activeQuoteData, setActiveQuoteData] = useState({
    address:{
    id:"",
    address_citya: "",
    address_countya: "",
    address_namea: "",
    address_numbera: "",
    address_postcodea: "",
    address_statea: "",
    address_streeta: "",
    customer_code: ""},
    
   quote:{
    id: 0,
    total_sum:0,
    customer_code:0,
    product_price:"",
    single_delivery:1,
    address_id:"",
    expiration_date:"",
    alternate_address:"",
    quote_order_items:[{created_at:''}]
   }
  });
  const tableTitles = React.useMemo(() => {
    const ActiveOrdersTableName = [
      "ProductName",
      "ProductID",
      "Order_Date",
      "Quantity",
      "Total_Cost",
      "Type",
      "To_Ship",
      "Delivery_Address",
      "Sub-Category",
      "cost"
    ];
    if (
      !Array.isArray(activeQuote?.quote?.quote_order_items) ||
      (Array.isArray(activeQuote?.quote?.quote_order_items) &&
      activeQuote?.quote?.quote_order_items === 0)
    ) {
      return ActiveOrdersTableName;
    }

    return ActiveOrdersTableName.map((title) => title);
  }, [activeQuote]);

  const  url_id = window.location.hash.substring(1)  
   const localfun = () => {
    setisUpdate(true)
  }
  const handleMount= async() => {
    await setStorageData('UPDATE',"CONFIRM_VIEW")
    window.location.hash.includes("#checkout") && setisUpdate(true)
  }
  const enterQuantity = (event : React.ChangeEvent<HTMLInputElement> , item  : IActiveOrder) => {
    const value = parseInt(event.target.value);
    if (isNaN(value) || value < 1) {
      setChangeValue(0)
    }else {
    setActiveOrder((prevItems:IActiveOrder[]) =>
      prevItems.map((data: IActiveOrder) =>
        data.id === item.id ? { ...data, quantity: value ,net_amount :Number(item.product_price)*value } : data
      )
    );
    setChangeValue(value)
    quantityUpdate(item.product_id, value);
  }
  }
  const handleTotalSum = () => {
    return activeOrder?.map((item: { net_amount: number }) => Number(item.net_amount)).reduce((sum, amount) => sum + amount, 0)
  }

  const handleSetActiveQuote = async() =>{
    if (activeQuote?.quote?.quote_order_items !== undefined) {
      if(changeValue === 0){
       setActiveOrder(activeQuote?.quote?.quote_order_items);
      } 
     const totalSum = activeOrder?.map((item:IActiveOrder)=> Number(item.net_amount)).reduce((sum, amount) => sum + amount, 0)
     const formatSum = formatPrice(totalSum)
     await setStorageData('total_sum',JSON.stringify(formatSum));
     await setStorageData(`confirmData`, JSON.stringify(activeOrder));
     }
 
     if (activeQuote !== undefined) {
       setActiveQuoteData(activeQuote);
     }
  }
  const handleIsUpdatePage = () => {
  return !isUpdate || !window.location.hash.includes("#checkout")
  }
  const renderPlaceorderButton = (daysDifference : number) => {
    let componentToRender;

    if (!isUpdate || !window.location.hash.includes("#checkout")) {
      componentToRender = (
        <Link href={`/Quotemanagement#${url_id}#checkout`}>
          <Button
            data-test-id="placeholder-btn"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={localfun}
          >
            {t("SubmitOrder")}
          </Button>
        </Link>
      );
    } else if (daysDifference !== 1) {
      componentToRender = (
        <PlaceOrderDialog
          page={page}
          t={t}
          view={"CONFIRM_DETAILS"}
          isLoading={Object.keys(loading).length > 0 || isOrderLoading}
          isSubmitted={isOrderSubmitted}
          submitOrder={submitOrder}
          onDialogClose={submitOrderDialogClose}
          addresses={addresses}
          createdOrderDetails={createdOrderDetails}
          navigateToOrderDetails={navigateToOrderDetails}
          createNewAddress={createNewAddress}
          createNewAddressLoading={newAddressLoading}
          isRequestAddressSubmitted={newAddressSubmitted}
          orderMethod={orderMethod}
          changeOrderMethod={changeOrderMethod}
          default_delivery_type={
            activeQuoteData?.quote?.single_delivery === 1
              ? "FINAL_DELIVERY_ONLY"
              : "PARTIAL_DELIVERY"
          }
          default_address_id={activeQuoteData?.address?.id.toString()}
          default_address={activeQuoteData?.quote?.alternate_address}
          placeOrderFromConfirmQuotes={placeOrderFromConfirmQuotes}
        />
      );
    } else {
      componentToRender = <CustomizedDialogs />;
    }
    
    return componentToRender;
  }
  useEffect(() => {
  handleSetActiveQuote()
  }, [activeQuote?.quote?.quote_order_items, activeQuote,changeValue]);

  useEffect(() => {
    handleMount()
    const handleBeforeUnload = async() => {
      removeStorageData(`confirmData`);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

    const expirationDate =  new Date (activeQuoteData?.quote?.expiration_date)
    const currentDate = new Date ()
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
  // Customizable Area End
  return (
    // Customizable Area Start
    <>
      {loading ? (
        <div data-testid="active-order-loading" className="loading-section">
          <CircularProgress style={{ width: 64, height: 64 }} color="primary" />
        </div>
      ) : (
        <div className="active-order-section">
          <Grid container spacing={3} className="top-section" >
            <Grid item component="div" className="order-details"  md={7}>           
                <Typography variant="h1" component="h1" className="page-title">
                  {t('ConfirmedQuotes')}
                </Typography>             
            
            <Typography>{new Date (activeQuoteData?.quote?.quote_order_items[0]?.created_at).toLocaleDateString('en-GB')}</Typography>
              <Typography
                data-testid="order-id-title"
                variant="h1"
                component="h1"
              >
                {t("Cart_ID")} {activeQuoteData?.quote?.id}
              </Typography>

              <Paper elevation={0} className="order-section-card">
                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1">
                    {t("Customer_ID")}
                  </Typography>
                  <span data-testid="customer-id">
                    {
                      activeQuoteData?.quote?.customer_code
                    }
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong" variant="subtitle1" >
                    {t("Order_Date")}
                  </Typography>
                  <span data-testid="order-date">-</span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong"  variant="subtitle1" >
                    {t("Total_Amount")}
                  </Typography>
                  <span data-testid="total-fees">
                    € {formatPrice(handleTotalSum())}
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography component="strong" variant="subtitle1" >
                    {t("Quote_Expiry")}
                  </Typography>
                  <span data-testid="order-date">{ daysDifference !== 1? `${daysDifference} ${t('Day')}`: t('Expired')}</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Table
          data-test-id = "ProductsTable"
            stickyHeader
            className="orders-products-table"
            titles={tableTitles}
            items={activeOrder?.map((item: IActiveOrder ,index : number) => {
              const address = `${activeQuoteData?.address?.address_citya}`+ " " +`${activeQuoteData?.address?.address_numbera}`+" "+`${activeQuoteData?.address?.address_countya}`+ " " +`${activeQuoteData?.address?.address_statea}`+ " " +`${activeQuoteData?.address?.address_postcodea}`;
             
              const options = {
                day: '2-digit' as const,
                month: '2-digit' as const,
                year: 'numeric' as const,
                hour: '2-digit' as const,
                minute: '2-digit' as const,
                second: '2-digit' as const,
                hour12: true as const
              };
  
              const formattedDatetime = new Date(activeQuoteData?.quote?.quote_order_items[0]?.created_at).toLocaleString('en-GB', options);
              const dateTimeParts = (formattedDatetime.toUpperCase()).replace(/,/g, '').split(' ');
              const date = dateTimeParts[0];
              const time = dateTimeParts[1] + ' ' + dateTimeParts[2];
              return {
                product_name: item.product_name,
                product_id: item.product_id,
                order_Date: <>{date}<br />{time}</>,  
                quantity: !handleIsUpdatePage() ?  <div className="quantity-input" data-test-id = {`input-container-${index}`}>
                <Input
                  data-test-id = {`input-quantity-confirm-${index}`}
                  defaultValue={item.quantity}
                  onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                    enterQuantity(event, item);
                  }}
                  type="number"
                  disabled={handleIsUpdatePage()}
                />
                {item.product_base_unit}
              </div> : item.quantity,             
                total_Cost: (
                  <div className="price">              
                      <span className="span">€</span><span>{formatPrice(Number(item.product_price) * item.quantity)}</span>
                  </div>
                ),
                type: activeQuoteData?.quote?.single_delivery === 1 ?"Fully":"Partially",
                to_ship: "Yes",
                Delivery_Address: activeQuoteData?.address !== null? address : activeQuote?.quote?.alternate_address,
                sub_category: item.product_sub_category,
                cost:formatPrice(item.product_price)
              };
            })}
          />
          <div
            className={classNames({
              "cart-table-footer": true,
              "__is-checkout": page === "CONFIRMED_QUOTE",
            })}
          >
            <strong className="total-cart-amount">
              {t("TotalQuoteAmount")}
            </strong>
            <span className="price" style={{fontWeight : 'bold'}}>           
              <span className="span"> € </span>
              <span>{formatPrice(handleTotalSum())}
              </span>                                  
            </span>
          </div>
          <div className="button-group" style={{display:'flex',justifyContent:`${!handleIsUpdatePage() ? "space-between" : "flex-end"}`,flexWrap:'wrap'}}>
          {!handleIsUpdatePage() && <div className="order-methods">
                  <RadioGroup aria-label="" name="" value={orderMethod} onChange={changeOrderMethod} >
                    <FormControlLabel value="PARTIAL_DELIVERY" control={<Radio color="primary" />} label={t(configJSON.needPartialDeliveryText)} />
                    <FormControlLabel value="FINAL_DELIVERY_ONLY" control={<Radio color="primary" />} label={t(configJSON.needFinalDeliveryText)} />
                  </RadioGroup>
                  <div className="options">
                  </div>
                </div>}
                {renderPlaceorderButton(daysDifference)}
              
          </div>
        </div>
      )}
    </>

    // Customizable Area End
  );
};

// Customizable Area Start

interface IProps {
  page: "SAVED_QUOTE" | "CONFIRMED_QUOTE";
  activeQuote: any;
  t: any;
  loading: boolean;
  id: any;
  submitOrder?: (addressId: string, alternateAddress?: string) => void;
  view: "LIST" | "DETAILS" | "CONFIRM_DETAILS";
  createNewAddress: (address: string) => void;
  submitOrderDialogClose: any;
  navigateToOrderDetails: any;
  createdOrderDetails: any;
  addresses: any;
  newAddressLoading: boolean;
  newAddressSubmitted: boolean;
  isOrderLoading: boolean;
  isOrderSubmitted: boolean;
  quantityUpdate: any;
  changeOrderMethod: (method: any) => void;
  orderMethod?: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
  fetchQuoteDetail?:(quote_id: any)=>void;
  fetchConfirmQuoteDetail?:(quote_id: string)=>void;
  placeOrderFromConfirmQuotes?: (addressId: string, alternateAddress?: string) => void;
}

interface IState {}

export default withTranslation()(ConfirmQuoteDetailsView);
// Customizable Area End
