import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IAddress, ICart, ICartProduct, IProduct } from "../../../components/src/types/types";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { addHashToUrl, formatPrice, isHashExist } from "../../../components/src/utilities/utils";
import { ICreatedOrderDetails } from "./SubmitOrderDialog";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t: any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  order_id: any;
  catalogue_id: number;
  quantity: number;
  taxable_value: number;
  taxable: boolean;
  token: string;
  orderList: any;
  orderItems: any;
  isRefreshing: boolean;
  isVisible: boolean;

  id: any;
  name: string;
  price: number;
  description: string;
  currency: string;
  category_id: number;
  updated_at: string;
  created_at: string;
  account_id: number;
  trendingList: any;
  cart: ICart;
  loading: Record<string, boolean>;
  isOrderLoading: boolean,
  isQuoteLoading:boolean,
  isOrderSubmitted: boolean,
  isQuoteSubmitted: boolean,
  hasStockIssue: boolean,
  orderMethod: 'PARTIAL_DELIVERY' | 'FINAL_DELIVERY_ONLY',
  addresses: (IAddress & { id: number })[],
  createdOrderDetails?: ICreatedOrderDetails,
  page: 'CART' | 'CHECKOUT',
  newAddressLoading: boolean,
  newAddressSubmitted: boolean,
  isCartLoading: boolean,
  activeCartId: number,
  product_id:number,
  createdQuoteDetails?: ICreatedOrderDetails,
  staticProductData:any;
  isOfferSubmitted:boolean;
  submitOffersStaticData:any;
  isOfferLoading:boolean;
  isStockCheck:boolean;
  isQuantityNoteOpen:boolean;
  isError:boolean, 
  validIDs : any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId: any;
  showOrderApiCallId: any;
  createOrderItemApiCallId: any;
  deleteOrderItemApiCallId: any;
  validationApiCallId: any = '';
  requestProductApiCallId: any = '';
  fetchAddressesApiCall: any = '';
  createAddressApiCallId: any = '';
  fetchShoppingCartCallId: any = '';
  fetchLanguageTextsCallId: any = '';
  saveQuoteApiCallId: any = '';
  notificationCallId: any = ''
  submitOffersApiCallId:any = ''

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.initCart = this.initCart.bind(this);
    this.fetchShoppingCart = this.fetchShoppingCart.bind(this);
    this.quantityUpdate = this.quantityUpdate.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.changeOrderMethod = this.changeOrderMethod.bind(this);
    this.submitOrderDialogClose = this.submitOrderDialogClose.bind(this);
    this.fetchShippingAddresses = this.fetchShippingAddresses.bind(this);
    this.hashListener = this.hashListener.bind(this);
    this.navigateToOrderDetails = this.navigateToOrderDetails.bind(this);
    this.createNewAddress = this.createNewAddress.bind(this);
    // Customizable Area Start
    this.saveQuote = this.saveQuote.bind(this);
    this.handleFetchShoppingCartResponse = this.handleFetchShoppingCartResponse.bind(this);
    this.navigateToQuoteDetails = this.navigateToQuoteDetails.bind(this); 
    this.submitOffers = this.submitOffers.bind(this);
    this.navigateConfirmeQuoteDetails = this.navigateConfirmeQuoteDetails.bind(this)
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      isVisible: false,
      orderItems: [],
      isRefreshing: false,

      id: 0,
      name: "",
      description: "",
      price: 0,
      created_at: "",
      category_id: 0,
      currency: "",
      updated_at: "",
      account_id: 0,
      cart: { products: {}, totalPrice: 0, prices: {}, productDetails: {} },
      trendingList: [],
      loading: {},
      isOrderLoading: false,
      isQuoteLoading:false,
      isQuoteSubmitted: false,
      isOrderSubmitted: false,
      hasStockIssue: false,
      orderMethod: 'PARTIAL_DELIVERY',
      addresses: [] as S['addresses'],
      page: 'CART',
      newAddressLoading: false,
      newAddressSubmitted: false,
      isCartLoading: true,
      activeCartId: -1,
      product_id:0,
      staticProductData: [],
      isOfferSubmitted:false,
      submitOffersStaticData:[],
      isOfferLoading:false,
      isStockCheck:false,
      isError:false , 
      isQuantityNoteOpen:false,
      validIDs : []
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (MergeEngineUtilities.isLoggedIn()) {
      this.initCart();
      this.fetchShippingAddresses();
    } else {
      this.validationApiCallId = await MergeEngineUtilities.validateToken(this.props);
      this.notificationCallId = await MergeEngineUtilities.getNotification()
    }
    
    this.fetchLanguageTextsCallId = MergeEngineUtilities.fetchLanguageTexts();

    if (window) { window.addEventListener('hashchange', this.hashListener) }
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.handleValidationResponse(responseJson, apiRequestCallId);
      this.handleSubmitResponse(responseJson, apiRequestCallId);
      this.handleAddressesResponse(responseJson, apiRequestCallId);
      this.handleCreateAddressResponse(responseJson, apiRequestCallId);
      this.handleFetchShoppingCartResponse(responseJson, apiRequestCallId);
      this.handleSavedQuoteResponse(responseJson,apiRequestCallId);
      this.handleSubmitOffersResponse(responseJson, apiRequestCallId)

      if (this.fetchLanguageTextsCallId === apiRequestCallId) {
        MergeEngineUtilities.handleLanguageResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      this.setState({
        page: 'CART',
        hasStockIssue: false,
        orderMethod: 'PARTIAL_DELIVERY',
      });
      
    }
    // Customizable Area End
  };

  // Customizable Area Start
  async componentWillUnmount() {
    if (window) {
      window.removeEventListener('hashchange', this.hashListener, true)
    }
  }

  hashListener() {
    const isHashRemoved = !isHashExist('checkout');
   
    if (isHashRemoved) {
      this.setState({ page: 'CART', hasStockIssue: false })
    }
  }

  handleValidationResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.validationApiCallId) {
      if (responseJson && Array.isArray(responseJson.messages) && responseJson.messages[0] && responseJson.messages[0].Token) {
        this.initCart()
        this.fetchShippingAddresses();
      } else {
        MergeEngineUtilities.logout(this.props);
      }
    }
    if (apiRequestCallId === this.notificationCallId) {
      if (responseJson.data?.length) {
        localStorage.setItem("Notification_count", responseJson?.data?.length) 
      }else{
        localStorage.setItem("Notification_count", '0') 
      }
    }
  }

  handleAddressesResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchAddressesApiCall) { return; }

    const state: Partial<S> = {}
    if (responseJson && Array.isArray(responseJson.addresses)) {
      state.addresses = responseJson.addresses;
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.setState((prevState) => ({ ...prevState, ...state }))
  }

  async handleSubmitResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.createOrderItemApiCallId) { return; }
    const state: Partial<S> = { isOrderLoading: false }

    if (responseJson && responseJson.data) {
      state.isOrderSubmitted = true;

      state.createdOrderDetails = {
        id: responseJson.data.id,
        customer_id: responseJson.data.attributes.customer.data.attributes.customer_code,
        created_at: new Date(responseJson.data.attributes.created_at).toLocaleDateString('en-GB'),
        status: responseJson.data.attributes.status,
        total_amount: formatPrice(responseJson.data.attributes.total_sum)!
      };

      const cart = await MergeEngineUtilities.cleanCart();
      state.cart = cart;
      this.notificationCallId = await MergeEngineUtilities.getNotification()
    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
  }
  async handleSavedQuoteResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.saveQuoteApiCallId) { return; }
    const state: Partial<S> = { isQuoteLoading: false }

    if (responseJson && responseJson.data) {
      state.isQuoteSubmitted = true;

      state.createdQuoteDetails = {
        id: responseJson.data.id,
        customer_id: responseJson.data.attributes.customer.data.attributes.customer_code,
        created_at: new Date(responseJson.data.attributes.created_at).toLocaleDateString('en-GB'),
        status: responseJson.data.attributes.status,
        total_amount: formatPrice(responseJson.data.attributes.total_amount)!
      };

      const cart = await MergeEngineUtilities.cleanCart();
      state.cart = cart;
      MergeEngineUtilities.navigateToScreen('Quotemanagement', this.props);
    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
  }
  async handleSubmitOffersResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.submitOffersApiCallId) { 
    const state: Partial<S> = { isOfferLoading: false }

    if (responseJson) {
      state.isOfferSubmitted = true;
      const cart = await MergeEngineUtilities.cleanCart();
      state.cart = cart;
      MergeEngineUtilities.navigateToScreen('Quotemanagement', this.props);
      
    } else {
      this.parseApiErrorResponse(responseJson)
    }

    this.setState((prevState) => ({ ...prevState, ...state }));
   }
  }
  
  handleCreateAddressResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.createAddressApiCallId) { return; }
    
    if (responseJson && responseJson.address) {
      this.setState({ newAddressSubmitted: true, newAddressLoading: false })
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  transformObject = (dataObject:any) => {
    const productId = Object.keys(dataObject)[0];
    const product_Id = dataObject[productId].product_id;
    const productName = dataObject[productId].name;
    const productDescription = dataObject[productId].description;
    const productBaseUnit= dataObject[productId].base_unit;
    const productCategory= dataObject[productId].category.name;
    const productSubCategory= dataObject[productId].sub_category.name;

    return {
      "static_product_name": productName,
      "static_product_description": productDescription,
      "static_base_unit": productBaseUnit,
      "static_category": productCategory,
      "static_sub_category": productSubCategory,
      "static_product_id": product_Id
    };
  };
  submitOffersTrasformObject = (submitdataObject:any) => {
    const productId = Object.keys(submitdataObject)[0];
    const product_Id = submitdataObject[productId].product_id;
    const productName = submitdataObject[productId].name;
    const productDescription = submitdataObject[productId].description;
    const productBaseUnit= submitdataObject[productId].base_unit;
    const productCategory= submitdataObject[productId].category.name;
    const productSubCategory= submitdataObject[productId].sub_category.name;

    return {
      "product_name": productName,
      "product_description": productDescription,
      "product_base_unit": productBaseUnit,
      "product_category": productCategory,
      "product_sub_category": productSubCategory,
      "product_id": product_Id,
      "to_ship": true,
      "fulfillment_type": "standard",
      "order_date": new Date().toLocaleDateString('en-GB')
    };
  };
  
  async handleFetchShoppingCartResponse(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId !== this.fetchShoppingCartCallId) {
      return;
    }
    
    const state: Partial<S> = { loading: {}, isCartLoading: false };
    const customerId = localStorage.getItem('customer_id');
    if (responseJson && responseJson.order_items && responseJson.order_items.length > 0) {
      const validOrderItems = responseJson.order_items.filter((item: any) => {
        const productId = item.attributes.catalogue.data.attributes.product_id;
        return !responseJson.errors?.some((error: any) => error.product_id === productId);
      });
  
      const validProductIds = validOrderItems.map(
        (item: { attributes: { catalogue: { data: { attributes: { product_id: any; }; }; }; }; }) =>
          `id_${item.attributes.catalogue.data.attributes.product_id}`
      );
  
      this.setState({ validIDs: validProductIds });
      const cart = await MergeEngineUtilities.addProductDetailsToCart(validOrderItems);
      const filteredProducts = Object.entries(cart.products)
        .filter(([pid]) => validProductIds.includes(pid))
        .reduce((acc, [pid, product]) => {
          acc[pid] = product;
          return acc;
        }, {} as typeof cart.products);
      state.cart = { ...cart, products: filteredProducts };
      
      localStorage.setItem(`cart_${customerId}`, JSON.stringify({ ...cart, products: filteredProducts }));
      const newArray = Object.keys(cart.productDetails).map(id => ({ [id]: cart.productDetails[id] }));
      const transformedArray = newArray.map(this.transformObject);
      this.setState({ staticProductData: transformedArray });
      const submitOffersArray = newArray.map(this.submitOffersTrasformObject);
      this.setState({ submitOffersStaticData: submitOffersArray });
  
      const allInStock = validOrderItems.map((item: any) => {
        const stockStatus = item.attributes.catalogue.data.attributes.stock_status;
        return stockStatus === "in_stock";
      });
      const allTrue = allInStock.every((status: boolean) => status === true);
      this.setState({ isStockCheck: allTrue });
    }
    
    if (responseJson.errors && responseJson.errors.length > 0) {
      this.setState({ isError: true });
      for (const error of responseJson.errors) {
        const productId = error.product_id;
        await MergeEngineUtilities.updateCart(productId, 0, 'DELETE');
       this.state.cart.totalPrice === 0 && this.initCart()
      }
    }
  
    this.setState(prev => ({ ...prev, ...state }));
    
  }
  
  changeOrderMethod(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ orderMethod: event.target.value as S['orderMethod'] })
  }

  findStaticData = (productId: any)=> {
    return this.state.staticProductData.find((staticData: { static_product_id: string; }) => staticData.static_product_id === productId);
  }

  findSubmitOffersStaticData = (productId: any)=> {
    return this.state.submitOffersStaticData.find((staticData: { product_id: string; }) => staticData.product_id === productId);
  }

  submitOrder(addressId?: string, alternateAddress?: string) {
    if (this.state.page === 'CART') {
      const id = this.state.validIDs
      const hasStockIssue = MergeEngineUtilities.checkCartStock(id);

      this.setState({
        page: 'CHECKOUT',
        hasStockIssue
      }, () => {
        addHashToUrl('checkout')
      });
    } else if (this.state.page === 'CHECKOUT') {
      this.setState({ isOrderLoading: true });
      const customerId = localStorage.getItem('customer_id');
      const cart =  localStorage.getItem('cart' + "_" + customerId);

      const data: any[] = [];

      if (cart) {
        const cartItems:ICart = JSON.parse(cart);
        Object.values(cartItems.products).forEach((item) => {
             const staticData = this.findStaticData(item.product_id);
             data.push({ product_id: item.product_id, quantity: item.cart_quantity, ...staticData});                       
        });
      } 
     
    if (data.length === 0) {
      return this.setState({ isCartLoading: false });
    }
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: `${configJSON.createOrderApiMethod}`,
        method: configJSON.postApiMethod,
        header: {},
        data: {
          order_items: data,
          status: 1,
          address_id: addressId !== 'alternate-address' && addressId,
          alternate_address: alternateAddress,
          delivery_type: this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1 ,
          quote_status : 0
        },
        useToken: true
      });

      this.createOrderItemApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  saveQuote(addressId?: string, alternateAddress?: string) {
    const id = this.state.validIDs
    const hasStockIssue = MergeEngineUtilities.checkCartStock(id);
    if(!hasStockIssue) {
      this.setState({page : 'CHECKOUT' , isQuoteLoading: true})
      const customerId = localStorage.getItem('customer_id');
      const cart =  localStorage.getItem('cart' + "_" + customerId);      
    
      const data: any[] = [];

      if (cart) {
        const cartItems:ICart = JSON.parse(cart);
        Object.values(cartItems.products).forEach((item) => {
            const staticData = this.findStaticData(item.product_id);
             data.push({ product_id: item.product_id, quantity: item.cart_quantity, ...staticData });                
        });
      }   

    if (data.length === 0) {
      return this.setState({ isCartLoading: false });
    }
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: `${configJSON.createOrderApiMethod}`,
        method: configJSON.postApiMethod,
        header: {},
        data: {
          order_items: data,
          status: 0,
          address_id: addressId !== 'alternate-address' && addressId,
          alternate_address: alternateAddress,
          delivery_type: this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1,
          quote_status: 1   
        },
        useToken: true
      });

      this.saveQuoteApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  submitOffers(addressId?: string, alternateAddress?: string) {
    const id = this.state.validIDs
    const hasStockIssue = MergeEngineUtilities.checkCartStock(id);
    if(!hasStockIssue) { 
      this.setState({ isOfferLoading: true , page : 'CHECKOUT' });
      const customerId = localStorage.getItem('customer_id');
      const submitOffersCart =  localStorage.getItem('cart' + "_" + customerId);      
    
      const submitOffersData: any[] = [];

      if (submitOffersCart) {
        const cartItems:ICart = JSON.parse(submitOffersCart);
        Object.values(cartItems.products).forEach((item) => {
            const submitStaticData = this.findSubmitOffersStaticData(item.product_id);
            submitOffersData.push({ product_id: item.product_id, quantity: item.cart_quantity, ...submitStaticData });                
        });
      }   

    if (submitOffersData.length === 0) {
      return this.setState({ isCartLoading: false });
    }
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: `${configJSON.submitOffersApiEndpoint}`,
        method: configJSON.postApiMethod,
        header: {},
        data: {
          quote_order_items: submitOffersData,
          address_id: addressId !== 'alternate-address' && addressId,
          alternate_address: alternateAddress,
          to_ship:1,
          single_delivery:this.state.orderMethod === 'FINAL_DELIVERY_ONLY' ? 0 : 1,
          quote_status:1,
          customer_code: localStorage.getItem('customer_code'),
          customer_id: localStorage.getItem('customer_id')
        },
        useToken: true
      });

      this.submitOffersApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  
  submitOrderDialogClose() {
    this.setState({
      isOrderSubmitted: false,
      isOrderLoading: false,
      newAddressLoading: false,
      newAddressSubmitted: false,
      isQuoteLoading:false,
      isQuoteSubmitted: false,
      isOfferLoading:false,
      isOfferSubmitted:false
    });
  }

  navigateToOrderDetails() {
    if (this.state.createdOrderDetails && this.state.createdOrderDetails.id) {
      MergeEngineUtilities.navigateToScreen('OrderManagement', this.props, {
        order_id: this.state.createdOrderDetails.id
      });
    }
  }

  navigateConfirmeQuoteDetails() {
    if (this.state.createdOrderDetails && this.state.createdOrderDetails.id) {
      MergeEngineUtilities.navigateToScreen('Quotemanagement', this.props, {
        order_id: this.state.createdOrderDetails.id
      });
    }
  }

  navigateToQuoteDetails() {   
    if (this.state.createdQuoteDetails && this.state.createdQuoteDetails.id) {
      MergeEngineUtilities.navigateToScreen('Quotemanagement', this.props, {
        order_id: this.state.createdQuoteDetails.id
      });
    }
    
  }

  fetchShippingAddresses() {
    const profile_id = MergeEngineUtilities._profile_id;

    const addressRequestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: `${configJSON.fetchAddressesMethod}?customer_code=${profile_id}`,
      method: configJSON.getApiMethod,
      header: {},
      useToken: true,
    })

    this.fetchAddressesApiCall = addressRequestMessage.messageId;

    runEngine.sendMessage(addressRequestMessage.id, addressRequestMessage);
  }

  fetchShoppingCart(cart: ICart) {
    const dataCart: any[] = [];
    Object.values(cart.products).forEach((item) => {
      dataCart.push({ product_id: item.product_id, quantity: item.cart_quantity });
    });

    if (dataCart.length === 0) {
      return this.setState({ isCartLoading: false });
    }

    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: configJSON.fetchShoppingCartApiEndpoint,
      method: configJSON.postApiMethod,
      data: {
        order_items: dataCart       
      },
      header: {},
      useToken: true,
    })

    this.fetchShoppingCartCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SavedQuote(cart: ICart) {
    const saveCartData: any[] = [];

    Object.values(cart.products).forEach((item) => {
      saveCartData.push({ product_id: item.product_id, quantity: item.cart_quantity });
    });

    if (saveCartData.length === 0) {
      return this.setState({ isCartLoading: false });
    }

    const requestMessage = MergeEngineUtilities.createRequestMessage({
      endpoint: configJSON.fetchShoppingCartApiEndpoint,
      method: configJSON.postApiMethod,
      data: {
        order_items: saveCartData,
        quote_status: 1       
      },
      header: {},
      useToken: true,
    })

    this.fetchShoppingCartCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    MergeEngineUtilities.navigateToScreen('Quotemanagement', this.props);
    MergeEngineUtilities.cleanCart();
  }

  async initCart() {
    const cart = await MergeEngineUtilities.initCart();
    this.fetchShoppingCart(cart);
  }

  async quantityUpdate(productId: ICartProduct['product_id'], qty: number) {
    const { cart } = this.state;
    const cartItem = cart.products[`id_${productId}`];
    if (!cartItem || (cartItem && cartItem.cart_quantity === qty)) { return; }
    this.handleMaxQuantity(cartItem,productId,Number(qty))
  }

  handleMaxQuantity=(cartItem:ICartProduct,productId:ICartProduct['product_id'],qnty:number)=>{
    const {cart} = this.state;
    if(!isNaN(qnty)){
      const maxAllowedQty = Number(cart.productDetails[`id_${productId}`]?.quantity_arriving) + cart.productDetails[`id_${productId}`]?.stock_qty;
      if(Number(qnty) > maxAllowedQty){
        this.handleIsQuantityNoteOpen(true)
      }else{
        this.handleIsQuantityNoteOpen(false)
        this.setState({
          loading: {
            [cartItem.product_id as string]: true,
          },
        }, () => {
          this.updateProductQuantityFromCart(productId, Number(qnty), Number(qnty) === 0 ? 'DELETE' : 'UPDATE', () => {
            this.fetchShoppingCart(this.state.cart)
          })
        })
      }
    }

  }

  async updateProductQuantityFromCart(
    productId: IProduct['product_id'],
    qty: number,
    operation: 'DELETE' | 'UPDATE',
    callback?: () => void
  ) {
    const cart = await MergeEngineUtilities.updateCart(productId, qty, operation);
    this.setState({ cart: cart }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    })
  }

  createNewAddress(address: string) {
    this.setState({
      newAddressLoading: true
    }, () => {
      const requestMessage = MergeEngineUtilities.createRequestMessage({
        endpoint: configJSON.requestProductApiEndPoint,
        method: configJSON.postApiMethod,
        data: {
          request_address: {
            full_address: address,
            customer_code: MergeEngineUtilities._profile_id
          }
        },
        header: {},
        useToken: true,
      })

      this.createAddressApiCallId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

 handleIsQuantityNoteOpen= async (value :boolean) =>{
  this.setState({
    isQuantityNoteOpen:value
  })
}
handleShowCart = (productLength : number) => {
  const {createdOrderDetails} = this.state;
  return Boolean(productLength > 0 || createdOrderDetails && !Boolean( createdOrderDetails.id))
}

handleIsStockAvailble = (productDetails :IProduct,field : string) => {
  if(field === 'nextArrival'){
    return !productDetails.stock_qty && productDetails.next_delivery_date ? new Date(productDetails.next_delivery_date).toLocaleDateString('en-GB') : '—';
  }else {
    return !productDetails.stock_qty && productDetails.quantity_arriving ? productDetails.quantity_arriving : '—';
  }
}
  // Customizable Area End
}
 // Customizable Area End
