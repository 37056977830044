import React from 'react';
// Customizable Area Start
import { Button, Typography, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Input from '../../../components/src/Input.web';
import Table from '../../../components/src/Table.web';
import ProductQuickView from '../../../components/src/ProductQuickView.web';
import Dialog from '../../../components/src/Dialog.web';
import MergeEngineUtilities from '../../utilities/src/MergeEngineUtilities';
import { ICart, IProduct } from '../../../components/src/types/types'

import { configJSON } from './CatalogueController';
import { debounce } from '../../../components/src/utilities/utils';
import { withTranslation } from 'react-i18next';

// Customizable Area End
export class ProductsTable extends React.Component<IProps, IState> {
    // Customizable Area Start
     dialogRef = React.createRef<Dialog>();
     scrollFn: any = null;

    constructor(props: IProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.submitRequestProductForm = this.submitRequestProductForm.bind(this);
        this.scrollTable = this.scrollTable.bind(this);
        this.getTableTitles = this.getTableTitles.bind(this);

        this.scrollFn = debounce(this.scrollTable, 300)

        this.state = {
            productName: '',
            productQuantities: {} as IState['productQuantities'],
            clickedProductId: -1,
            isStockAvailable: {}
        }
    }

    componentDidMount() {
        const { cart } = this.props;
        const quantities: Record<string, number> = {}

        Object.entries(cart.products).forEach(([id, product]: [string, any]) => {
            if (!product || !product.cart_quantity) { return; }
            quantities[id] = product.cart_quantity;
        })

        this.setState({
            productQuantities: quantities
        })
    }

    openDialog() {
        if (this.dialogRef.current ) {
            this.dialogRef.current.openDialog();
        }
    }

    closeDialog(isSelfClose = false) {
        this.setState({
            productName: '',
            errorMessage: '',
        }, () => {
            this.props.onDialogClose();

            if (isSelfClose) {
                this.dialogRef.current?.closeDialog();
            }
        });
    }

    onInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.value;
    
        if (val) {
            this.setState({ productName: val });
        } else {
            this.setState({
                errorMessage: configJSON.requestProductDialogErrorMessage
            });
        }
    }
    
    onInputFocus() {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: '' });
        }
    }
    

    submitRequestProductForm() {
        const { errorMessage, productName } = this.state;
    
        if (errorMessage) {
            return;
        }
    
        if (!productName) {
            this.setState({ errorMessage: configJSON.requestProductDialogErrorMessage });
            return;
        }
    
        this.props.onFormSubmit(productName);
    }

    addProductToCart(product: IProduct) {
        let { productQuantities } = this.state;
        const { cart } = this.props;
        const key = `id_${product.product_id}`

        const qty = productQuantities[key];
        if (!qty && qty !== 0 || (qty === 0 && !cart.products[`id_${product.product_id}`])) {
            const quantityInput = document.querySelector(`[data-pid="${product.product_id}"] input`) as HTMLInputElement;
            if (quantityInput) {
                return quantityInput.focus();
            }
        }

        if (typeof qty !== 'string') {
            // @ts-ignore
            this.setState({ clickedProductId: product.product_id });


            setTimeout(() => {
                this.props.onCartItemUpdate(product.product_id, qty);
                this.setState({ clickedProductId: -1 });
            }, 1000);
        }
    }

    changeProductQuantity(product_id: any, qty: number,product:any) {
        const { productQuantities } = this.state;
        const key = `id_${product_id}`;

        if (qty < 0 || Number.isNaN(qty)) {
            productQuantities[key] = 0;
        } else {
            productQuantities[key] = qty;
        }
        let obj={
            isStockForCheck:product?.stock_qty<qty,
            product_id:product?.product_id,
            stock_qty:product?.stock_qty,
            quantity_arriving:product?.quantity_arriving,
            next_delivery_date:product?.next_delivery_date
        }

      this.setState({ productQuantities ,isStockAvailable:obj})

    }

    scrollTable(event: any) {
        const el = document.querySelector('.pantalone-table');
        if (!el) { return; }

        if (this.props.isNewProductsLoading) { return; }

        if (el.scrollHeight - el.scrollTop <= 700) {
            this.props.fetchCatalogue((this.props.products.length / 50) + 1);
        }
      }

    getTableTitles() {
        const { products,t } = this.props;
        const Titles = ["ProductID","ProductName","Category","Sub-Category","Price/Mt","Quantity", ""];
        if (products.length > 0) {
            const isAllRowsSame = products.every((product) => product.base_unit === products[0].base_unit);
            Titles[4] = isAllRowsSame ? `${t("price")}/${products[0].base_unit}` : t("Price/Unit");
        }
           return Titles.map((title:any) => t(title));

    }

    isStockUnavailable = (product: any) => {
        return this.state.isStockAvailable.product_id&&this.state.isStockAvailable.product_id === product.product_id 
            && this.state.isStockAvailable.isStockForCheck&&this.state.isStockAvailable.isStockForCheck;
    }
    renderHeading = () => {
        const { products, productSearchValue ,t} = this.props;
       return <div className="table-title-section">
            <Typography component="h3" variant="h1">{productSearchValue ? `${t('SearchResult')}: ${productSearchValue}` : t('ProductName')}</Typography>
            <span>{t("Result")} ( {products.length} )</span>
        </div>
    }

    isWarningNote = () => {
        const {t} = this.props;
      return <span id='inputContainerErrorMsg'> 
        {`${t('Wecurrentlyhave')} ${this.state.isStockAvailable?.stock_qty} ${t('quantityavailableforthisitem')}`}
        {Boolean(this.state.isStockAvailable?.quantity_arriving) && Boolean(this.state.isStockAvailable?.next_delivery_date) &&
            ` ${t('Weexpecttohave')} ${this.state.isStockAvailable?.quantity_arriving} ${t('quantityavailableon')} ${this.state.isStockAvailable.next_delivery_date}.`
        }
    </span>
    }
    addProductToCartText = (product:IProduct) => {
        const {t} = this.props;
        const cartItem = this.props.cart['products'][`id_${product.product_id}`];
       return (cartItem && cartItem.cart_quantity >= 0) ? t(configJSON.updateCartText) : t(configJSON.addToCartText)
     }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { products, productSearchValue ,t} = this.props;

        return (
            <div className="products-table">
                {products.length > 0 ? <>
               {this.renderHeading()}
    <Table
    data-test-id="tableID"
    stickyHeader
    tableContainerProps={{
        onScroll: this.scrollFn
    }}
    titles={this.getTableTitles()}
    items={products.map((product, index) => {
        const qty = this.state.productQuantities[`id_${product.product_id}`];
        const cartItem = this.props.cart['products'][`id_${product.product_id}`];

        return {
            id: (
                <div className="id-item" data-test-id={`id-item-${index}`}>
                    {product.product_id}
                    <ProductQuickView
                        data-test-id={`product-quick-view-${product.product_id}`}
                        id={product.product_id}
                        name={product.name}
                        description={localStorage.getItem('language') === "en" ? product.description : product.alternate_description}
                    />
                </div>
            ),
            name: product.name,
            category: product.category.name,
            subcategory: product.sub_category.name,
            price: (
                <div className="price" data-test-id={`price-${product.product_id}`}>
                    <span className="span">€</span>
                    <span>{MergeEngineUtilities.getProductPrice<string>(product)}</span>
                </div>
            ),
            quantity: (
                <div className="quantity-input" data-pid={product.product_id} data-test-id={`quantity-input-${product.product_id}`}>
                    <div id="inputContainerMain">
                        <div id='inputContainerError'>
                            
                            <Input
                                data-test-id={`quantity-input-field-${index}`} 
                                value={qty}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.changeProductQuantity(product.product_id, parseInt(event.target.value), product);
                                }}
                            />
                            <span>{product.base_unit}.</span>
                        </div>

                        <div>
                            {this.isStockUnavailable(product) && this.isWarningNote()}
                        </div>
                    </div>
                </div>
            ),
            action: (
                <Button
                    color="primary"
                    variant="contained"
                    className="add-to-cart update-animation"
                    disabled={this.isStockUnavailable(product) || Number(this.state.productQuantities[`id_${product?.product_id}`]) > Number(product?.stock_qty)}
                    onClick={() => this.addProductToCart(product)}
                    data-test-id={`add-to-cart-${index}`} 
                >
                    {this.addProductToCartText(product)}

                    {product.product_id === this.state.clickedProductId && (
                        <span className="icon-area">
                            <CheckCircleIcon className="check-circle-icon" />
                        </span>
                    )}
                </Button>
            )
                        }
                    })}
                > 
    {this.props.isNewProductsLoading && (
        <div className="loading-section" data-test-id="loading-section"> 
            <CircularProgress
                color="primary"
                style={{ width: 48, height: 48 }}
            />
        </div>
    )}
</Table>
</>
: <span style={webStyle.noProductsFoundNote}>{t("NoProductsFoundForSelectedFilter")}</span>}
                <div className="no-products-found">
                    <span />
                    <span>{t(configJSON.requestProductDialogProductNotFoundText)}</span>
                    <Button data-test-id="opendilogbox"  onClick={this.openDialog} color="primary" variant="contained">{t(configJSON.requestProductDialogRequestProductText)}</Button>
                </div>

                <Dialog
                    id="request-new-product"
                    title={this.props.isRequestProductSubmitted ? t('Success') : t(configJSON.requestProductDialogRequestProductText)}
                    ref={this.dialogRef}
                    closable
                    onClose={this.closeDialog}
                >
                    {this.props.isRequestProductSubmitted ? (
                        <div className="submitted-view">
                            <CheckCircleIcon className="check-icon" />

                            <Typography variant="body2" className="description">
                                {t(configJSON.requestProductDialogSuccessText)}
                            </Typography>

                            <Button
                                className="rounded-button"
                                variant="contained"
                                color="primary"
                                onClick={() => this.closeDialog(true)}
                                fullWidth
                            >
                                {t(configJSON.requestProductDialogSuccessButton)}
                            </Button>
                        </div>
                    ) : (
                        <div className="form-view">
                            {this.props.isRequestProductLoading === true ? (
                                <div className="loading-section">
                                    <CircularProgress
                                        color="primary"
                                        style={{ width: 64, height: 64 }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <Typography variant="body2" className="description">
                                        {t(configJSON.requestProductDialogText)}
                                    </Typography>

                                    <Input
                                    data-test-id="productdetailsdilog"
                                        type="text"
                                        label={t(configJSON.requestProductDialogEmailLabel)}
                                        onBlur={this.onInputBlur}
                                        onFocus={this.onInputFocus}
                                        errorMessage={<span>{t(this.state.errorMessage)}</span>}
                                        fullWidth
                                    />

                                    <Button
                                    data-test-id="rounded-buttons"
                                        className="rounded-button"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.submitRequestProductForm}
                                        fullWidth
                                    >
                                        {t(configJSON.requestProductDialogButtonText)}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Dialog>
            </div>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
export interface IProps {
    products: IProduct[],
    isRequestProductSubmitted: boolean,
    isRequestProductLoading: boolean,
    onFormSubmit: (email: string) => void,
    onDialogClose: () => void,
    onCartItemUpdate: (productId: IProduct['product_id'], qty: number) => void,
    fetchCatalogue: (pageNumber: number) => void,
    cart: ICart,
    productSearchValue?: string,
    isNewProductsLoading: boolean,
    t?: any,
    // isStockAvailable:boolean
}
export interface IState {
    clickedProductId: IProduct['id'] | string
    productName: string,
    errorMessage?: string,
    productQuantities: Record<string, number | string>
    isStockAvailable:any
}

const webStyle = {
    noProductsFoundNote : {
        display:"flex",
        justifyContent:"center",
        alignItems:'center',
        fontSize:'14px',
        fontWeight:"bold" as "bold",
    }
}
export default withTranslation()(ProductsTable);
// Customizable Area End 