import React from 'react';
// Customizable Area Start
import { Grid, Chip, Typography } from '@material-ui/core';
import { classNames } from '../../../components/src/utilities/utils';
import { withTranslation } from 'react-i18next';

const TEXTS: Record<IProps['filterType'], string> = {
    MATERIAL: 'Material',
    DIAMETER: 'Diameter',
    THICKNESS: 'Sch',
    RATING: 'Rating',
}
// Customizable Area End
class Filters extends React.Component<IProps, IState> {
    // Customizable Area Start
    constructor(props: IProps) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    onItemClicked(item: any, idx: number) {
        this.props.onItemClicked(item, this.props.filterType)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { filterItems, valueIds, filterType, t } = this.props;

        return (
             <div className="filter-items">
                <Typography className="filter-title">{t(TEXTS[filterType])}</Typography>

                <Grid container spacing={2}>
        {filterItems?.map((item: any, idx: number) => (
          <Grid item key={item}>
            {item !== '' && (
              <Chip
                label={item}
                onClick={() => this.onItemClicked(item, idx)}
                color={valueIds.includes(item) ? "primary" : "default"} // Selected if value is in valueIds
                className={classNames({
                  '__chip-item': true,
                  '__is-selected': valueIds.includes(item), // Add selected class
                })}
              />
            )}
          </Grid>
        ))}
      </Grid>
            </div>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
interface IFilterItem {
    id: number,
    name: string,
}

interface IProps {
    filterItems: any,
    filterType: 'MATERIAL' | 'DIAMETER' | 'THICKNESS' | 'RATING',
    valueIds: number[]
    onItemClicked: (itemId: number, type: IProps['filterType']) => void
    t:any
}

interface IState {
}
export {Filters}
export default withTranslation()(Filters);
// Customizable Area End