import * as React from "react";
// Customizable Area Start
import {
  IconButton,
  CircularProgress,
  Box,
  Table as MuiTable, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper
} from "@material-ui/core";
import MainLayout from "../../../components/src/MainLayout.web";
import Input from "../../../components/src/Input.web";
import FilterBy from "./FilterBy";
import './ordermanagement.css';
import ActiveOrderDetails from "./ActiveOrderDetails.web";
import SortBy from "./SortBy";
import { withTranslation } from "react-i18next";
import CustomTooltip from "../../../components/src/Tooltip.web";
import GetAppIcon from '@material-ui/icons/GetApp';
// Customizable Area End

import { ViewProps, configJSON } from "./OrderManagement";

const OrderManagementView: React.FC<ViewProps> = ({
  // Customizable Area Start
  view,
  navigation,
  orders,
  searchValue,
  sortValue,
  filterValue,
  activeOrder,
  loading,
  shippingAddresses,
  onSearchValueChanged,
  onSearchFormSubmit,
  navigateToOrderDetail,
  onSortValueChanged,
  onFilterValueChanged,
  t,
  shippingAlternateAddresses, 
  handleScroll
  // Customizable Area End
}) => {

  // Customizable Area Start
  
  const OrderTableName = [
    { title: "Order_ID" },
    { title: "Customer_ID" },
    { title: "Shipping_Address" },
    { title: "Order_Date" },
    { title: "Total_Amount" },
    { title: "Status" },
    { title: "" },
  ];

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchValueChanged(event.target.value);
  }
  // Customizable Area End
  
  return (
    // Customizable Area Start
      <MainLayout
        id="order-management"
        navigation={navigation}
        title={t(view === 'DETAILS' ? '' : configJSON.ordersTitle)}
      >
        {view === 'DETAILS' ? (
          <ActiveOrderDetails
            activeOrder={activeOrder}
            loading={loading}
          />
        ) : (
          <div className="orders-section">
            {loading ? (
              <div className="loading-section" data-testid="orders-loading">
                <CircularProgress
                    color="primary"
                    style={{ width: 64, height: 64 }}
                />
              </div>
            ) : (
              <>
                <div className="filters-area">
                  <form autoComplete='off' onSubmit={onSearchFormSubmit}>
                    <Input
                      defaultValue={searchValue}
                      type="search"
                      name="search"
                      placeholder={t('SearchByOrderID')}
                      autoComplete="off"
                      onInput={onSearchInputChange}
                      fullWidth
                    />
                  </form>
               <div style={{display:"flex",justifyContent: "flex-end"}}>
                  <SortBy
                    sortValue={sortValue}
                    onSortValueChanged={onSortValueChanged}
                  />

                  <FilterBy
                    filterValue={filterValue}
                    onFilterValueChanged={onFilterValueChanged}
                    addresses={shippingAddresses }
                    alternateAddress={shippingAlternateAddresses}
                  />
                </div>
                </div>

                {(orders !== null && Array.isArray(orders) && orders.length > 0) ? (
                 <Box data-testid='scrollable-order' className="scrollable-order-table" maxHeight= "80vh" overflow="auto" onScroll={handleScroll}> 
                      <TableContainer component={Paper} className="pantalone-table">
                        <MuiTable>
                          <TableHead>
                            <TableRow>
                              {OrderTableName.map((item: { title: string } | string) => typeof item !== "string" && t(item.title)).map((title, index) => <TableCell key={index}>{t(title)}</TableCell>)}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {orders.map((order) => {
                              return {
                                id: order.id,
                                customer_id: order.customer_id,
                                shipping_address: (
                                  <div className="shipping-address-item">
                                    <b>{order.customer_fullname}</b>
                                    <span>{order.customer_address?.slice(0,15) + "..."}</span>
                                  </div>
                                ),
                                order_date: order.order_date,
                                totalAmount: (
                                  <div className="price">
                                    <span className="span">€ </span>
                                    <span>{order.total_amount}</span>
                                  </div>
                                ),
                                status: order.status,
                                action: (
                                  <CustomTooltip title={t('Download')}>
                                    <IconButton
                                      className="action-button"
                                      data-testid="action-button"
                                      onClick={(event)=>{ event.stopPropagation()}}
                                    >
                                      <GetAppIcon color="primary"  style={{ width: 30, height: 30 }}/>
                                    </IconButton>
                                  </CustomTooltip>
                                )
                              }
                            }).map((item) => (
                              <TableRow key={item.id} onClick={() => navigateToOrderDetail(item.id)}>
                                {Object.values(item).map((details: string | JSX.Element | Date, index) => (
                                  <TableCell key={index}>{details}</TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </MuiTable>
                      </TableContainer>
                  </Box>
                  ) : <Box style={webStyle.noOrdersNote}>{t(configJSON.noOrdersFound)}</Box>}
              </>
            )}
          </div>
        )}

      </MainLayout>
    // Customizable Area End
  );
};

// Customizable Area Start
const webStyle = {
  noOrdersNote :{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: '100%',
      height: '350px',
      fontWeight: 600,
  }
}
// Customizable Area End

export default withTranslation()(OrderManagementView);
